<template>
  <div class="login">
    <!-- 头部 -->
    <div class="login-header">
      <div class="login-header-content">
        <a href="https://www.tianyandata.cn">
          <img
            src="https://z3.ax1x.com/2021/05/17/gRPbXd.png"
            class="login-header-logo"
          ></a>

        <img
          src="../../assets/img/login/logo.png"
          class="login-header-tip"
        >
      </div>
    </div>

    <!-- 登录 -->
    <div class="login-content-container">
      <div class="login-content-box">
        <div class="login-content">
          <!-- 登录方式 -->
          <div class="login-content-table">
            <div
              :class="['content-table-list',{'content-active-table':loginActive == 0}]"
              @click="loginActive=0"
            >
              <p class="content-table-text">密码登录</p>
            </div>
            <div
              :class="['content-table-list',{'content-active-table':loginActive == 1}]"
              @click="loginActive=1"
            >
              <p class="content-table-text">验证码登录</p>
            </div>
          </div>

          <!-- 登录输入框 -->
          <div>
            <div class="login-input el-input el-input-group el-input-group--prepend">
              <div class="el-input-group__prepend">
                <img
                  src="../../assets/img/login/icon1.png"
                  class="login-input-icon"
                >
              </div>
              <input
                type="text"
                autocomplete="off"
                placeholder="手机号"
                class="el-input__inner"
                v-model="phoneNum"
              >
            </div>

            <!-- 密码登录 -->
            <div
              v-if="loginActive == 0"
              class="login-input el-input el-input-group el-input-group--prepend"
            >
              <div class="el-input-group__prepend"><img
                  src="../../assets/img/login/icon2.png"
                  class="login-input-icon"
                ></div><input
                type="password"
                autocomplete="off"
                placeholder="密码"
                class="el-input__inner"
              >
            </div>

            <!-- 验证码登录 -->
            <div
              v-if="loginActive == 1"
              class="input-container"
            >
              <div class="login-input login-code-input el-input el-input-group el-input-group--prepend">
                <div class="el-input-group__prepend"><img
                    src="../../assets/img/login/icon3.png"
                    class="login-input-icon"
                  ></div><input
                  type="text"
                  autocomplete="off"
                  placeholder="验证码"
                  class="el-input__inner"
                >
              </div> <button
                type="button"
                class="el-button active-btn el-button--default"
                @click="GetCode()"
              ><span>获取验证码</span>
              </button>
            </div>
          </div>

          <button
            type="button"
            class="el-button login-btn el-button--primary"
            @click="loginClick()"
          >
            <span>登录</span>
          </button>

          <div class="login-tip-container">
            <router-link to='Forget'>
              <p class="forget-tip">忘记密码？</p>
            </router-link>
            <p class="register-tip">还没有账号，<router-link to='Register'><span class="register-link">马上注册</span></router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="login-content-footer">
      <div class="content-footer-content">
        <div class="footer-content-list"><a
            href="https://www.tianyandata.cn"
            class="footer-content-tip"
          >平台首页</a></div>
        <div class="footer-content-list"><a
            href="https://www.tianyandata.cn/product"
            class="footer-content-tip"
          >产品服务</a></div>
        <div class="footer-content-list"><a
            href="https://www.tianyandata.cn/support"
            class="footer-content-tip"
          >服务支持</a></div>
      </div>
      <p class="login-copy-tip">Copyright © 2020 上海兰铂旺网络科技有限公司 沪ICP备20008428号-2</p>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  name: "Login",
  data() {
    return {
      loginActive: 0, // 登录方式  0密码 1验证码
      phoneNum: "",
    };
  },
  components: {},
  methods: {
    // 获取验证码
    GetCode() {
      if (this.isPone()) {
        ElMessage.success({ message: "验证码发送成功" });
      }
    },

    // 登录按钮 点击
    loginClick() {
      if (this.isPone()) {
        switch (this.loginActive) {
          case 0: // 密码登录
            console.log("密码登录");
            break;
          case 1: // 验证码登录
            console.log("验证码登录");
            break;
        }
      }
    },

    // 判断 手机号
    isPone() {
      if (this.phoneNum) {
        if (this.isPoneAvailable(this.phoneNum)) {
          return true;
        } else {
          ElMessage.error("请输入正确的手机号");
        }
      } else {
        ElMessage.warning({
          message: "手机号不能为空哦",
        });
      }
    },

    // 判断是否为手机号
    isPoneAvailable(pone) {
      let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(pone)) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
// @import url("./css/login.css");
</style>