import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue' // 首页
import Product from '../views/Product.vue' // 产品服务
import ProductDetail from '../views/ProductDetail.vue' // 产品详情
import AboutUs from '../views/AboutUs.vue' // 产品详情

import createOrder from '../views/pay/createOrder.vue' // 确认支付
import payOrder from '../views/pay/payOrder.vue' // 支付

import Login from '../views/login/Login.vue' // 登录页
import Register from '../views/login/Register.vue' // 注册页
import Forget from '../views/login/Forget.vue' // 忘记密码


const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/productDetail',
    name: 'ProductDetail',
    component: ProductDetail
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/createOrder',
    name: 'createOrder',
    component: createOrder
  },
  {
    path: '/payOrder',
    name: 'payOrder',
    component: payOrder
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/forget',
    name: 'Forget',
    component: Forget
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => ({ y: 0 }), //路由跳转后页面回到顶部
  routes
})


router.beforeEach((to, from, next) => {
  next()
})

export default router
