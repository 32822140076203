<template>
  <div class="content-list-box">
    <div
      class="content-title-box"
      v-if="isTitle"
    >
      <p class="home-content-title">//<span>{{ list.title }}</span>//</p>
      <a
        @click="moreClick(list.title)"
        class="home-content-more"
      >更多 &gt;</a>
    </div>

    <div
      class="content-main-box"
      v-if="isList"
    >
      <div
        class="icon_box"
        v-if="isImgShow"
      >
        <img
          :src="list.titleImg"
          class="content-box-icon"
        />

        <div v-if="list.type == 3" @click="jumpClick(list.id)">更多>></div>
      </div>

      <div class="content-right-box">
        <div
          class="cont_list"
          :class="{'hots-right-list': item.classType == 1, 'content-right-list': item.classType == 2, 'cont_list_child': list.type == 1}"
          @click="jumpUrl(item.id)"
          v-for="(item, idx) in list.lbwBizTypeList"
          :key="idx"
        >
          <div class="right-icon-box">
            <img
              :src="item.icon"
              :alt="item.name"
              class="content-right-icon"
            />
          </div>

          <div
            class="right-list-box"
            v-if="item.classType == 1"
          >
            <p class="right-list-name">{{ item.name }}</p>
            <p class="right-list-description">
              {{ item.comment }}
            </p>
            <div class="right-detail-box">
              <p class="right-list-price">
                <span v-if="item.unitPrice">{{ item.unitPrice }}元/次</span>
                <span v-if="!item.unitPrice">单价联系客服</span>
              </p>
              <p class="right-list-details">详情 &gt;</p>
            </div>
          </div>

          <div
            class="right-list-box"
            v-if="item.classType == 2"
          >
            <p
              :title="item.name"
              class="right-list-name"
            >
              {{ item.name }}
            </p>
            <p class="right-list-price">
              <span v-if="item.unitPrice">{{ item.unitPrice }}元/次</span>
              <span v-if="!item.unitPrice">单价联系客服</span>
            </p>
            <p class="right-list-details">详情 &gt;</p>
          </div>
        </div>
      </div>

      <div
        class="icon_box"
        v-if="!isImgShow"
      >
        <img
          :src="list.titleImg"
          class="content-box-icon"
        />

        <div v-if="list.type == 3" @click="jumpClick(list.id)">更多>></div>
      </div>
    </div>

    <div
      class="content-main-box"
      v-if="isArrList"
    >
      <div
        class="icon_box"
        v-if="isImgShow"
      >
        <img
          :src="list.lbwBizTypeFunList[0].titleImg"
          class="content-box-icon"
        />

        <div v-if="list.lbwBizTypeFunList[0].type == 3" @click="jumpClick(list.lbwBizTypeFunList[0].id)">更多>></div>
      </div>

      <div class="content-right-box">
        <div
          class="cont_list"
          :class="{'hots-right-list': item.classType == 1, 'content-right-list': item.classType == 2}"
          @click="jumpUrl(item.id)"
          v-for="(item, idx) in list.lbwBizTypeFunList[0].lbwBizTypeList"
          :key="idx"
        >
          <div class="right-icon-box">
            <img
              :src="item.icon"
              :alt="item.name"
              class="content-right-icon"
            />
          </div>

          <div
            class="right-list-box"
            v-if="item.classType == 1"
          >
            <p class="right-list-name">{{ item.name }}</p>
            <p class="right-list-description">
              {{ item.comment }}
            </p>
            <div class="right-detail-box">
              <p class="right-list-price">
                <span v-if="item.unitPrice">{{ item.unitPrice }}元/次</span>
                <span v-if="!item.unitPrice">单价联系客服</span>
              </p>
              <p class="right-list-details">详情 &gt;</p>
            </div>
          </div>

          <div
            class="right-list-box"
            v-if="item.classType == 2"
          >
            <p
              :title="item.name"
              class="right-list-name"
            >
              {{ item.name }}
            </p>
            <p class="right-list-price">
              <span v-if="item.unitPrice">{{ item.unitPrice }}元/次</span>
              <span v-if="!item.unitPrice">单价联系客服</span>
            </p>
            <p class="right-list-details">详情 &gt;</p>
          </div>
        </div>
      </div>

      <div
        class="icon_box"
        v-if="!isImgShow"
      >
        <img
          :src="list.lbwBizTypeFunList[0].titleImg"
          class="content-box-icon"
        />

        <div v-if="list.lbwBizTypeFunList[0].type == 3" @click="jumpClick(list.lbwBizTypeFunList[0].id)">更多>></div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
export default {
  name: "funList",
  props: {
    list: {
      type: Object,
      default: {},
    },
    isTitle: {
      type: Boolean,
      default: true,
    },
    isList: {
      type: Boolean,
      default: true,
    },
    isArrList: {
      type: Boolean,
      default: false,
    },
    isImgShow: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    let type = props.list.type;

    var typeArr = [];

    switch (type) {
      case 1:
        typeArr = [1, 1, 2, 2, 2];
        props.list.lbwBizTypeList = props.list.lbwBizTypeList.slice(0, 5);
        break;
      case 2:
        typeArr = [1, 1, 1, 1];
        props.list.lbwBizTypeList = props.list.lbwBizTypeList.slice(0, 4);
        break;
      case 3:
        typeArr = [2, 2, 2, 2, 2, 2];
        props.list.lbwBizTypeList = props.list.lbwBizTypeList.slice(0, 6);
        break;

      default:
        typeArr = [2, 2, 2, 2, 2, 2];
        props.list.lbwBizTypeFunList[0].lbwBizTypeList =
          props.list.lbwBizTypeFunList[0].lbwBizTypeList.slice(0, 6);
        break;
    }

    for (let i = 0; i < typeArr.length; i++) {
      if (props.isArrList) {
        if (props.list.lbwBizTypeFunList[0].lbwBizTypeList[i]) {
          props.list.lbwBizTypeFunList[0].lbwBizTypeList[i]["classType"] =
            typeArr[i];
        }
      } else {
        if (props.list.lbwBizTypeList[i]) {
          props.list.lbwBizTypeList[i]["classType"] = typeArr[i];
        }
      }
    }

    const myData = toRefs(
      reactive({
        typeArr: typeArr,
      })
    );

    const myMethods = {
      jumpUrl(id) {
        let url = this.$router.resolve({
          path: "/productDetail",
          query: {
            id: id,
          },
        });

        // console.log(url);

        window.open(url.href, "_blank");
      },
    };

    return {
      ...myData,
      ...myMethods,
    };
  },
  methods: {
    // 大类 更多 点击
    moreClick(title) {
      let url;

      if (title == "热门API" || title == "所有分类") {
        url = this.$router.resolve({ path: "/product" });
      } else if (title == "新品推荐") {
        url = this.$router.resolve({
          path: "/product",
          query: { orderBy: 1 },
        });
      }

      // console.log(url);

      window.open(url.href, "_blank");
    },
    // 分类 下 更多 点击
    jumpClick(id) {
      let url = this.$router.resolve({
        path: "/product",
        query: { catalogId: id },
      });

      // console.log(url);

      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.icon_box {
  position: relative;

  div {
    position: absolute;
    bottom: 36px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    border: 1px solid #fff;
    padding: 12px 37px;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;

    &:hover {
      font-size: 15px;
    }
  }
}
</style>
