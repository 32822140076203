<template>
  <div class="aboutUs1">

    <!-- header Start -->

    <com-header
        :tabindex="3"
        :isIndex="true"
    />

    <!-- header End -->

    <!-- banner -->
    <div class="banner_warp">
      <img src="../assets/img/aboutUs/about_us_banner.png">
    </div>

    <!-- content -->
    <!-- 服务优势 -->
    <div class="container">
      <p class="titleP">//<span>服务优势</span>//</p>

      <div class="ser_adv_wrap">
        <div class="ser_adv_us">
          <img src="../assets/img/aboutUs/icon1.png" />
          <span class="sp1">权威数据，合规授权</span>
          <span class="sp2">
            通过权威机构合规授权，进行数据源接入，可提供覆盖面广，权威的反欺诈风控核验服务。可满足各类企业在不同场景下的业务需求。
          </span>
        </div>
        <div class="ser_adv_us">
          <img src="../assets/img/aboutUs/icon2.png" />
          <span class="sp1">一站接入，操作便捷</span>
          <span class="sp2">
            数据安全稳定，实时连续支持多系统对接，整合各类权威数据资源，內企业提供一站式接收服务。提高效率，降低企业运营时间和成本。
          </span>
        </div>
        <div class="ser_adv_us">
          <img src="../assets/img/aboutUs/icon3.png" />
          <span class="sp1">安全高效，服务稳定</span>
          <span class="sp2">
            平台采用标准https加密协议和AP/接口，实现企业的快速，安全，便捷的数据接收。毫秒级响应，每秒超过100个并发能力，可支持干万级以上数据处理能力。公司采用云服务技术，完善的运维监管体系，提供7*24小时专业运营服务。
          </span>
        </div>
        <div class="ser_adv_us">
          <img src="../assets/img/aboutUs/icon4.png" />
          <span class="sp1">注重行业，专业服务</span>
          <span class="sp2">
            针对日趋猖獗的黑产欺诈产业，兰铂旺专注反欺诈风控领域，通过机器学习技术，不断提升企业综合技术实力，建立立体，专业的风控服务体系。
          </span>
        </div>
      </div>
    </div>

    <!-- 荣誉资质 -->
    <div
        class="container"
        style="padding-bottom:58px"
        id="iframe_"
    >
      <p class="titleP">//<span>荣誉资质</span>//</p>

      <div class="tag_list">
        <div
            :class="[
                'tag_title',
                { 'tag_title_active': tabState == 0 },
              ]"
            @click="tabState = 0"
        >
          <span>公司资质</span>
          <div :class="{'div_active': tabState == 0}"></div>
        </div>
        <div
            :class="[
                'tag_title',
                { 'tag_title_active': tabState == 1 },
              ]"
            @click="tabState = 1"
        >
          <span>软件证书</span>
          <div :class="{'div_active': tabState == 1}"></div>
        </div>
      </div>

      <div class="swiperBox">
        <iframe
            src="http://121.4.162.141:890/index.html"
            frameborder="0"
            v-if="tabState == 0"
        ></iframe>

        <iframe
            src="http://121.4.162.141:890/index2.html"
            frameborder="0"
            v-if="tabState == 1"
        ></iframe>
      </div>
    </div>

    <!-- 联系我们 -->
    <div
        class="container"
        id="sh_about_us_contact_us"
    >
      <p class="titleP">//<span>联系我们</span>//</p>

      <div class="contact_us_wrap">
        <div class="contact_us">
          <img src="../assets/img/aboutUs/icon6.png" />
          <span class="sp1">咨询电话</span>
          <span class="sp2 sp3">021-58356602</span>
        </div>
        <div class="contact_us">
          <img src="../assets/img/aboutUs/icon5.png" />
          <span class="sp1">邮箱</span>
          <span class="sp2">business@99num.com</span>
        </div>
        <div class="contact_us">
          <img src="../assets/img/aboutUs/icon7.png" />
          <span class="sp1">联系地址</span>
          <span class="sp2">上海市浦东新区祥科路58号B栋</span>
        </div>
      </div>
    </div>

    <!-- 加入我们 -->
    <div
        class="container"
        style="margin-bottom:20px;padding-bottom:49px"
    >
      <p class="titleP">//<span>加入我们</span>//</p>

      <div class="join_us_wrap1">
        <div class="join_us">
          <div class="div1">
            <span>数据产品经理</span>
            <span class="sp1">15K~20K</span>
          </div>
          <div class="div2">
            <span>上海浦东</span>
            <span class="sp1">3年以上</span>
            <span>本科招若干人</span>
          </div>
          <div class="div3">2021.01.07</div>
        </div>
        <div class="join_us">
          <div class="div1">
            <span>软件工程师</span>
            <span class="sp1">12K~18K</span>
          </div>
          <div class="div2">
            <span>上海浦东</span>
            <span class="sp1">3~5年</span>
            <span>本科招若干人</span>
          </div>
          <div class="div3">2021.01.07</div>
        </div>
        <div class="join_us">
          <div class="div1">
            <span>架构师</span>
            <span class="sp1">25K~35K</span>
          </div>
          <div class="div2">
            <span>上海浦东</span>
            <span class="sp1">5年以上</span>
            <span>本科招若干人</span>
          </div>
          <div class="div3">2021.01.07</div>
        </div>
        <div class="join_us">
          <div class="div1">
            <span>运营专员</span>
            <span class="sp1">6K~12K</span>
          </div>
          <div class="div2">
            <span>上海浦东</span>
            <span class="sp1">5年以上</span>
            <span>本科招若干人</span>
          </div>
          <div class="div3">2021.01.07</div>
        </div>
        <div class="join_us">
          <div class="div1">
            <span>销售经理</span>
            <span class="sp1">10K~15K</span>
          </div>
          <div class="div2">
            <span>上海浦东</span>
            <span class="sp1">1~3年</span>
            <span>本科招若干人</span>
          </div>
          <div class="div3">2021.01.07</div>
        </div>
        <div class="join_us">
          <div class="div1">
            <span>销售总监</span>
            <span class="sp1">15K~25K</span>
          </div>
          <div class="div2">
            <span>上海浦东</span>
            <span class="sp1">3年</span>
            <span>本科招若干人</span>
          </div>
          <div class="div3">2021.01.07</div>
        </div>
      </div>

      <div class="join_us_wrap2">
        <span>联系人：周女士</span>
        <span class="sp1">电话：021-58356602</span>
        <span>邮箱：hr@99num.com</span>
      </div>
    </div>

    <!-- footer Start -->

    <com-footer />

    <!-- footer End -->
  </div>
</template>

<script>
import ComHeader from "@/components/header.vue"; // 引入header组件
import ComFooter from "@/components/footer.vue"; // 引入footer组件

export default {
  name: "aboutUs1",
  data() {
    return {
      tabState: 0,
    };
  },
  components: {
    "com-header": ComHeader,
    "com-footer": ComFooter,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.swiperBox {
  margin: 35px 25px 0;
  background: #f4faff;

  iframe {
    display: block;
    height: 390px;
    width: 100%;
    background: #f4faff;
  }
}

.aboutUs1 {
  color: #333333;

  // banner
  .banner_warp {
    img {
      width: 100%;
      display: block;
    }
  }

  // 公用
  .container {
    width: 1200px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 20px;

    // title
    .titleP {
      text-align: center;
      font-size: 28px;
      color: #999999;
      padding: 30px 0 73px;

      span {
        padding: 0 30px;
        font-weight: bold;
        color: #043649;
      }
    }

    // 服务优势
    .ser_adv_wrap {
      display: flex;
      justify-content: space-between;
      padding: 0 25px 58px;

      .ser_adv_us {
        width: 276.25px;
        display: flex;
        flex-direction: column;
        text-align: center;
        background: #f4faff;
        padding: 40px 26px;
        box-sizing: border-box;

        img {
          width: 50px;
          height: 50px;
          display: block;
          margin: 0 auto;
        }

        .sp1 {
          font-weight: bold;
          padding: 40px 0 15px;
        }

        .sp2 {
          font-weight: 400;
          font-size: 12px;
          text-align: justify;
        }
      }
    }

    // 荣誉资质
    .tag_list {
      display: flex;
      margin-left: 50px;

      .tag_title {
        margin-right: 60px;
        font-weight: 600;
        cursor: pointer;

        .div_active {
          width: 22px;
          height: 2px;
          background: #0091ff;
          border-radius: 1px;
          margin: 0 auto;
          margin-top: 15px;
        }
      }

      .tag_title_active {
        color: #0091ff;
      }
    }

    // 联系我们
    .contact_us_wrap {
      display: flex;
      justify-content: space-between;
      padding: 0 155px 58px;

      .contact_us {
        width: 218px;
        display: flex;
        flex-direction: column;
        text-align: center;

        img {
          width: 50px;
          height: 50px;
          display: block;
          margin: 0 auto;
        }

        .sp1 {
          font-weight: bold;
          padding: 40px 0 15px;
        }

        .sp2 {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    // 加入我们
    .join_us_wrap1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0 25px;

      .join_us {
        width: 373.33px;
        background: #f4faff;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 20px;
        box-sizing: border-box;

        &:nth-of-type(3n) {
          margin-right: 0;
        }

        .div1 {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          font-size: 18px;

          .sp1 {
            font-size: 19px;
            color: #fa6400;
          }
        }

        .div2 {
          border-bottom: 1px solid #eee;
          margin: 15px 0 20px;
          padding-bottom: 20px;
          font-size: 12px;
          font-weight: 400;

          .sp1 {
            display: inline-block;
            margin: 0 10px;
            padding: 0 10px;
            border-right: 1px solid #ccc;
            border-left: 1px solid #ccc;
          }
        }

        .div3 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .join_us_wrap2 {
      background: #f4faff;
      padding: 30px 0;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      margin: 0 25px;

      .sp1 {
        display: inline-block;
        margin: 0 60px;
        padding: 0 60px;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
      }
    }
  }
}
</style>
