<template>
  <div class="register">
    <!-- 头部 -->
    <div class="login-header">
      <a href="https://www.tianyandata.cn">
        <img
          src="https://z3.ax1x.com/2021/05/17/gRPbXd.png"
          class="login-header-logo"
        ></a>
    </div>

    <!-- 注册内容 -->
    <div class="login-content-container">
      <div class="register-content">
        <p class="register-title">兰铂旺用户注册</p>
        <div class="input-container">
          <div class="register-input el-input">
            <input
              type="text"
              autocomplete="off"
              placeholder="请输入手机号"
              class="el-input__inner"
            >
          </div>
        </div>

        <div class="input-container">
          <div class="register-code-input el-input">
            <input
              type="text"
              autocomplete="off"
              placeholder="请输入短信验证码"
              class="el-input__inner"
            >
          </div>

          <button
            type="button"
            class="el-button active-btn el-button--default"
          >
            <span>获取验证码</span>
          </button>
        </div>

        <div class="input-container">
          <div class="register-input el-input">
            <input
              type="password"
              autocomplete="off"
              placeholder="请输入密码"
              class="el-input__inner"
            >
          </div>
        </div>

        <div class="input-container">
          <div class="register-input el-input">
            <input
              type="password"
              autocomplete="off"
              placeholder="请再次输入密码"
              class="el-input__inner"
            >
          </div>
        </div>

        <!-- 是否选定 注册协议 -->
        <div class="agreement-container">
          <label
            :class="['el-checkbox', {'is-checked':radioChecked}]"
            @click="radioChecked=!radioChecked"
          >
            <span :class="['el-checkbox__input', {'is-checked':radioChecked}]">
              <span class="el-checkbox__inner"></span>
            </span>
          </label>
          <p class="agreement-text">我已同意<span
              class="agreement-name"
              @click="protocolShow = true"
            > 《兰铂旺注册协议》</span>
          </p>
        </div>

        <!-- 注册按钮 -->
        <button
          type="button"
          class="el-button register-btn el-button--primary"
        >
          <span>立即注册</span>
        </button>

        <div class="login-tip-container">
          <!-- <p class="type-tip">邮箱注册&gt;&gt;</p> -->
          <p class="register-tip">
            <router-link to='Login'>
              <span class="register-link">已有账号，立即登录</span>
            </router-link>
          </p>
        </div>
      </div>
    </div>

    <!-- 注册协议 -->
    <div
      class="el-dialog__wrapper"
      v-if="protocolShow"
      @click="protocolShow = false"
    >
      <div
        class="el-dialog"
        @click.stop=''
      >
        <com-protocol />

        <div class="el-dialog__footer">
          <span class="dialog-footer">
            <button
              type="button"
              class="el-button el-button--primary"
              @click="protocolShow = false"
            >
              <span>我已阅读</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComProtocol from "@/components/protocol.vue"; // 引入protocol组件

export default {
  name: "Register",
  data() {
    return {
      radioChecked: true, // 是否 选定注册协议
      protocolShow: false, // 注册协议 是否显示
    };
  },
  components: {
    "com-protocol": ComProtocol,
  },
};
</script>

<style lang="less" scoped>
.register {
  .login-tip-container {
    justify-content: flex-end;
  }

  .el-dialog__wrapper {
    z-index: 2000;
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>