<template>
  <div class="el-dialog__body">
    <p class="register-text">
      本《兰铂旺用户服务协议》（以下简称“本《协议》”）系由用户与兰铂旺就兰铂旺（https://99num.com）提供的数据技术服务所订立的相关权利义务规范。您在注册为兰铂旺用户前，需仔细阅读并完全理解本《协议》内容及兰铂旺公布的其他服务规则（以下统称为“服务条款”），如果您不同意受该等服务条款的约束，应停止注册程序并停止使用兰铂旺服务。当您点击兰铂旺注册页面的同意注册按钮并完成注册程序、获得兰铂旺账号和密码时，即视为您已认真阅读服务条款全部内容，对服务条款均已知晓、理解并接受，同意将其作为确定双方权利义务的依据 服务条款对您及兰铂旺均具有法律效力。
      兰铂旺是由杭州数脉科技有限公司运营和管理的为用户（包括个人及单位）提供API测试、API申请、API调用、数据定制等多项服务的交易平台，官方网站为 https://99num.com。除本《协议》有明确规定外，本《协议》不适用使用兰铂旺特别数据技术服务的个人或单位，对于使用兰铂旺特别数据技术服务的用户，请阅读为该等服务制定的特别条款，详见兰铂旺官方网站。
      如本协议约定的条款与用户和公司签订的纸质合同中各条款有不同之处，以纸质合同为准；纸质合同没有约定的事项，以用户在公司平台注册使用过程中同意的各项服务条款为准。
    </p>
    <p class="register-text agreement-title">
      1、服务对象
    </p>
    <p class="register-text">
      1.1 同时满足下列条件的，方可被认定为兰铂旺用户：
    </p>
    <p class="register-text">
      （1）同意本《协议》内容并接受其约束；
    </p>
    <p class="register-text">
      （2）完成在兰铂旺的注册程序并获得兰铂旺账户和密码;
    </p>
    <p class="register-text">
      1.2 兰铂旺用户类型及实名认证
    </p>
    <p class="register-text">
      1.2.1 兰铂旺用户类型：
    </p>
    <p class="register-text">
      （1）个人用户；
    </p>
    <p class="register-text">
      （2）单位用户；
    </p>
    <p class="register-text">
      1.2.2 实名认证说明：
    </p>
    <p class="register-text">
      （1）个人用户进行实名认证需按照兰铂旺的要求提供包括但不限于个人身份证件正反面照片、个人基本信息以及兰铂旺认为为向用户提供满足用户需求的服务所必需的其他信息；
    </p>
    <p class="register-text">
      （2）单位用户进行实名认证需按照兰铂旺的要求提供包括但不限于个营业执照正副本照片、从事与兰铂旺合作业务或使用兰铂旺服务所必需的资质许可（如需）、企业基本信息以及兰铂旺认为为向用户提供满足用户需求的服务所必需的其他信息;用户应保证其向兰铂旺提供的证件、信息等内容真实、准确、完整、及时、有效，因用户提供的证件、信息等内容不满足真实、准确、完整、及时、有效条件而引致的各种损失或责任，均由客户自行承担。
    </p>
    <p class="register-text">
      （3）用户应保证其向兰铂旺提供的实名制认证的证件、信息等内容真实、准确、完整、及时、有效，因用户提供的证件、信息等内容不满足真实、准确、完整、及时、有效条件而引致的各种损失或责任，均由用户自行承担。
    </p>
    <p class="register-text">
      （4）用户应保证如为企业实名制客户应保证用户注册及后续平台操作行为（包括但不限于注册、充值、购买、消费、申请退款等）均有有效授权，否则由此导致的各种损失或责任，均由用户自行承担。
    </p>
    <p class="register-text agreement-title">
      2、服务内容
    </p>
    <p class="register-text">
      2.1 兰铂旺技术服务说明
    </p>
    <p class="register-text">
      兰铂旺用户可以使用兰铂旺提供的数据API及JuheData Android SDK、JuheData iOS SDK，用于各个平台上的APP开发。另外根据数据的特性不同，不同数据具有不同的资费标准及赠送次数。详细如下：
    </p>
    <p class="register-text">
      （1）用户成功申请数据，即可获得一定量的赠送测试次数，具体内容请参见相应的接口文档页。
    </p>
    <p class="register-text">
      （2）兰铂旺注册用户可使用兰铂旺公布的全部数据资源。
    </p>
    <p class="register-text">
      （3）不同数据具有不同的请求频次，请用户关注所申请的数据的请求频次，具体内容请参见相应的接口文档页。
    </p>
    <p class="register-text">
      （4）若用户提出恶意请求（兰铂旺享有自主判断的权利）、未经兰铂旺同意采集兰铂旺数据或通过兰铂旺平台作出侵犯兰铂旺合法权益的其他行为的，兰铂旺将停止服务，并视情形需要确定是否采取法律维权措施；
    </p>
    <p class="register-text">
      （5）兰铂旺有权根据市场运作、服务内容、发展规划等因素调整相应数据技术服务收费标准，并于官网或相应的接口文档页进行公告，而无需单独通知用户。用户在购买相应的数据技术服务时，应关注最新服务收费标准；
    </p>
    <p class="register-text">
      兰铂旺平台为提高用户体验、完善用户服务质量，将视需要合理使用兰铂旺平台产生的用户数据，但不会作出泄露用户隐私的行为。您注册成为兰铂旺用户，即视为您已同意并充分有效授权兰铂旺数据在不违反客户隐私保护义务的前提下对您在兰铂旺平台的数据/信息进行合理使用。
    </p>
    <p class="register-text">
      2.2 数据定制服务
    </p>
    <p class="register-text">
      如兰铂旺提供的一般性服务无法满足用户的需求，兰铂旺愿意为用户提供特殊定制服务。用户可联系兰铂旺客服咨询定制服务相关信息。
    </p>
    <p class="register-text agreement-title">
      3、知识产权与数据
    </p>
    <p class="register-text">
      3.1 您同意（并应取得您的用户事先同意）在此授予兰铂旺免费的、永久性的、不可撤销的、非独家的和不可转让的权利和许可，在本协议期限内使用您（及您的用户）的标志或行为来宣传您（及您的用户）对本服务的使用。
    </p>
    <p class="register-text agreement-title">
      3.2 运营数据是指您使用兰铂旺服务过程中产生的全部或任何数据，包括但不限于您（及您的用户）向兰铂旺平台提交的数据及返回的数据、您的使用兰铂旺平台行为产生的数据等。运营数据的所有权及其他相关权利均属于兰铂旺，且是兰铂旺的商业秘密，除非相关法律法规明确规定属于您（或您的用户）的合法相关权利。
    </p>
    <p class="register-text agreement-title">
      3.3 用户数据指您在使用兰铂旺数据服务中产生的与用户相关的数据，包括但不限于您提交的文本数据、图像数据及服务返回的数据等。用户数据的所有权及其他相关权利均属于兰铂旺，且是兰铂旺的商业秘密，除非相关法律法规明确规定属于您（或您的用户）的合法相关权利。除非事先获得您（或您的用户）的授权或本协议另有规定外，兰铂旺不会将用户数据对外公开或向第三方提供。兰铂旺可能会向兰铂旺的关联公司提供用户数据，并要求其遵循兰铂旺的隐私权政策以及其他任何相应的保密和安全措施来处理和使用这些数据。您的应用或服务对于用户数据的收集、保存、使用等必须满足以下要求：
    </p>
    <p class="register-text">
      （1）您的应用或服务需要收集用户任何数据的，必须事先获得用户的明确同意，且仅应当收集为应用程序运行及功能实现目的而必要的用户数据，同时应当告知用户相关数据收集的目的、范围及使用方式等，保障用户知情权。
    </p>
    <p class="register-text">
      （2）您采集的用户数据，应采取合理、安全的技术措施，确保其安全性，并对自己的行为（包括但不限于自行安装软件、采取加密措施或进行其他安全措施等）所引起的结果承担全部责任。
    </p>
    <p class="register-text agreement-title">
      4、服务内容的变更、中断和结束
    </p>
    <p class="register-text">
      （1）兰铂旺致力于为用户提供更优质更稳定的服务，因此兰铂旺可能会在服务期间对平台进行优化升级。兰铂旺进行优化升级之前，将会在平台相应位置进行公告，请用户实时关注。因优化升级而暂时中断为用户提供服务，不视为兰铂旺违反服务条款，兰铂旺不因此向客户承担任何责任；
    </p>
    <p class="register-text">
      （2）兰铂旺将通过优化服务、技术升级等方式为用户提供优质、稳定、持续的服务，但因不可抗力因素导致的服务中断或影响服务质量，用户理解并豁免兰铂旺与此相关的任何责任。不可抗力因素包括但不限于自然灾害、罢工、骚乱、物质短缺或定量配给、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故、计算机病毒或黑客攻击；
    </p>
    <p class="register-text">
      3）若用户不再需要兰铂旺数据技术服务，用户可向兰铂旺官方邮件shumai@shumaidata.com发出用户身份注销申请，，经兰铂旺审议通过，双方终止协议关系，兰铂旺将停止为用户提供服务；
    </p>
    <p class="register-text">
      （4）用户需持续满足兰铂旺规定的用户条件，若用户不再符合兰铂旺的用户要求，兰铂旺有权作出终止服务、注销用户账户的决定。用户对此有任何异议，可向兰铂旺提出异议申请；
    </p>
    <p class="register-text">
      （5）除遵守服务条款外，用户在使用兰铂旺服务时，还应遵守《兰铂旺用户服务协议》项下义务，否则兰铂旺有权中断服务，或者拒绝用户的数据技术服务申请。
    </p>
    <p class="register-text">
      （6）用户应持续遵守服务条款，若用户存在违反服务条款的情形，兰铂旺有权采取必要措施，甚至停止服务。
    </p>
    <p class="register-text agreement-title">
      5、用户的帐号，密码及管理
    </p>
    <p class="register-text">
      （1）在完成兰铂旺用户的注册程序后，用户将获得一个兰铂旺账户，该账户密码由用户设置并保管，兰铂旺无法获悉。用户有权通过兰铂旺用户中心管理其账户，包括但不限于补充、更新用户信息，修改密码等，用户应妥善保管账户信息及密码；
    </p>
    <p class="register-text">
      （2）用户应当遵守法律法规、规章、规范性文件及政策要求，谨慎合理使用和妥善保管兰铂旺账号及密码，对通过其兰铂旺账号和密码进行的行为和发生的事件负责。当用户发现兰铂旺账号被未经其授权的第三方使用或存在其他账号安全问题时应立即有效通知兰铂旺，要求兰铂旺暂停该兰铂旺账号的服务，并向公安机关报案。兰铂旺有权在合理时间内对用户的该等请求采取行动，配合用户作出相应处理措施，但对用户遭受的损失不承担任何责任。用户在未经兰铂旺同意的情况下不得将兰铂旺账号以赠与、借用、租用、转让或其他方式处分给他人。用户通过兰铂旺与商家进行在线交易时，应当遵守本协议“在线交易规则”的全部约定及兰铂旺发布的其他关于在线交易的服务条款和操作规则的全部规定。用户在兰铂旺对商品/服务进行评价时应当根据实际情况如实进行评价；
    </p>
    <p class="register-text">
      （3）为更好的为用户提供服务，维护用户合法权益，兰铂旺将采取有效措施保护用户的账户信息。若用户在使用兰铂旺账户时发现安全漏洞，请立即以有效方式通知兰铂旺。<span class="agreement-title">虽然兰铂旺将全力、持续完善账户维护技术手段，但受限于目前的技术水平，兰铂旺无法保证所采取的措施都是有效和安全无虞的，用户知晓、理解并豁免兰铂旺因此产生的责任。</span></p>
    <p class="register-text agreement-title">
      6、用户义务
    </p>
    <p class="register-text">
      6.1 不得利用兰铂旺提供的数据危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益，不得利用兰铂旺提供的数据制作、复制和传播下列信息：
    </p>
    <p class="register-text">
      （1）煽动抗拒、破坏宪法和法律、行政法规实施的；
    </p>
    <p class="register-text">
      （2）煽动颠覆国家政权，推翻社会主义制度的；
    </p>
    <p class="register-text">
      （3）煽动分裂国家、破坏国家统一的；
    </p>
    <p class="register-text">
      （4）煽动民族仇恨、民族歧视，破坏民族团结的；
    </p>
    <p class="register-text">
      （5）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；
    </p>
    <p class="register-text">
      （6）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；
    </p>
    <p class="register-text">
      （7）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；
    </p>
    <p class="register-text">
      （8）损害国家机关信誉的；
    </p>
    <p class="register-text">
      （9）其他违反宪法和法律行政法规的；
    </p>
    <p class="register-text">
      6.2 用户应当遵守法律法规、规章、规范性文件及政策要求的规定，不得在兰铂旺或利用兰铂旺服务从事非法或其他损害兰铂旺或第三方权益的活动，包括但不限于发送或接收任何违法、违规、违反公序良俗、侵犯他人权益的信息，发送或接收传销材料或存在其他危害的信息或言论。
    </p>
    <p class="register-text">
      6.3 用户承诺不使用兰铂旺产品服务进行任何不符合中国法律法规及政策的行为，如有违反产生的一切法律责任由其自身承担而与兰铂旺无关。如用户因违反该承诺而对兰铂旺造成的任何损害，用户应当赔偿一切损失。
    </p>
    <p class="register-text agreement-title">
      6.4 用户知悉、确认并保证，按照本协议约定使用的兰铂旺平台提供的数据技术服务，若为非公开数据且根据相关规定需获得数据提供方或者数据相关主体的同意或授权，兰铂旺向用户提供该等数据时视为用户已经取得所有合法、有效且必需的同意或授权（统称“有效授权”），兰铂旺有权随时抽查用户取得有效授权的情况，用户应积极配合。若因用户未取得或未及时取得有效授权而使用该等数据产生任何纠纷或责任，概由用户自行负责，且用户应积极采取一切行之有效之行为（包括但不限于对兰铂旺进行全额赔偿），以使兰铂旺免于因此承担任何责任或遭受任何损失。
    </p>
    <p class="register-text agreement-title">
      7、违约责任
    </p>
    <p class="register-text">
      （1）如因兰铂旺违反有关法律、法规或本《协议》项下义务而给用户造成损失，兰铂旺同意承担相应的损害赔偿责任，并向用户赔偿用户的直接损失；
    </p>
    <p class="register-text">
      （2）用户同意保障和维护兰铂旺及其他用户的利益，如因用户违反有关法律、法规或本《协议》约定而给兰铂旺或任何其他第三者造成损失，用户应承担相应的法律责任。
    </p>
    <p class="register-text agreement-title">
      8、隐私政策
    </p>
    <p class="register-text agreement-title">
      8.1 概述
    </p>
    <p class="register-text">
      兰铂旺十分重视用户信息的保护，兰铂旺将会采取合理措施保护您（及您的用户）的隐私权。<span class="agreement-title">为了向您（及您的用户）提供更好的服务，您同意（并应取得您的用户事先同意）并授权本平台基于服务必要性获取相应的信息和数据，不论是您（及您的用户）主动提供的还是使用本服务过程中获取的信息和数据。 您（及您的用户）提供的信息和数据将用于改善兰铂旺的服务，向您（及您的用户）提供更好的用户体验以及满足您（及您的用户）的需求等。</span></p>
    <p class="register-text">
      如果您（或您的用户）不同意提供相关信息和数据，或您不保证在您的用户知情、并授权允许的情况下使用本服务，您（或您的用户）将无法使用本服务的部分或者全部。<span class="agreement-title">一旦您（及您的用户）选择使用本服务，即表示您认可（并已取得您的用户的事先认可）并接受本协议中隐私政策的内容以及兰铂旺可能随时更新并公布的新内容。</span></p>
    <p class="register-text">
      兰铂旺会以高度的勤勉义务对待这些信息，除非事先获得您（或您的用户）的授权或本协议另有规定外，不会将这些信息对外公开或向第三方提供。兰铂旺可能会向兰铂旺的关联公司提供您（或您的用户）的个人信息，并要求其遵循兰铂旺的隐私权政策以及其他任何相应的保密和安全措施来处理和使用这些信息。
    </p>
    <p class="register-text">
      如因用户数据、用户肖像信息等应用造成您的直接用户的投诉、主张、诉讼等纠纷，您应当负责自行处理并承担全部法律责任，同时应保证兰铂旺免于因此而遭受任何损失或损害。如兰铂旺因任何政府决定、法院判决等对包括您及您的用户在内的任何人承担单独或连带赔偿责任的，您应当对兰铂旺作出相应数额的补偿，以保证兰铂旺免于因此而遭受任何损失或损害。
    </p>
    <p class="register-text agreement-title">
      8.2 信息披露
    </p>
    <p class="register-text">
      在如下情况下，兰铂旺可能会披露您（或您的用户）的信息：（1）事先获得您（或您的用户）的授权；（2）您（及您的用户）使用共享功能；（3）根据法律、法规、法律程序的要求或政府主管部门的强制性要求；（4）以学术研究或公共利益为目的；（5）为维护兰铂旺的合法权益，例如查找、预防、处理欺诈或安全方面的问题；（6）符合相关服务条款或使用协议的规定；（7）以无法合理被用于识别您（及您的用户）身份的汇总和/或匿名的形式分享您（及您的用户）的信息。
    </p>
    <p class="register-text agreement-title">
      8.3 信息保护
    </p>
    <p class="register-text">
      兰铂旺非常重视信息安全，兰铂旺成立了专责团队，研发和应用多种安全技术和程序等，通过这些严格措施努力保护您的信息不被未经授权的访问、使用或泄漏。维护本服务的安全与正常使用是兰铂旺和您（及您的用户）的共同责任，兰铂旺将采取适当的安全措施和技术手段维护本服务，但是您充分理解并同意（并应促使您的用户充分理解并同意），兰铂旺并不能就此提供完全保证。
    </p>
    <p class="register-text agreement-title">
      9、免责申明
    </p>
    <p class="register-text agreement-title">
      本免责申明规定了兰铂旺特定情形下不承担责任的内容，与用户权益息息相关，用户应仔细、逐项阅读。若用户不同意本免责申明的内容，应停止注册或停止使用兰铂旺服务并注销兰铂旺账户。用户完成注册程序并使用兰铂旺的服务，视为用户已经完全知悉、理解本免责申明对其权利、义务、责任的特别约定，并同意接受该等约定，豁免兰铂旺的相应责任。
    </p>
    <p class="register-text">
      ——为服务用户的目的，兰铂旺可能通过使用用户的个人信息，向用户提供用户感兴趣的信息，包括但不限于向用户发出产品和服务信息，或者与兰铂旺关联方、兰铂旺合作伙伴共享信息以便他们向用户发送有关其产品和服务的信息（后者需要用户的事先同意）。
    </p>
    <p class="register-text">
      ——兰铂旺可能会对产品使用情况进行统计。同时，兰铂旺可能会与公众分享这些统计信息，以展示兰铂旺服务的整体使用趋势。这些统计信息不包含用户的任何身份识别信息。
    </p>
    <p class="register-text">
      —— 在如下情况下，兰铂旺可能会披露用户的信息：（1）事先获得用户的授权；（2）用户使用共享功能；（3）根据法律、法规、法律程序的要求或政府主管部门的强制性要求；（4）以学术研究或公共利益为目的；（5）为维护兰铂旺的合法权益，例如查找、预防、处理欺诈或安全方面的问题；（6）符合相关服务条款或使用协议的规定。
    </p>
    <p class="register-text">
      —— 由于兰铂旺无法控制数据供应商资源，用户了解并同意，此类数据供应商资源是否可供用户利用，兰铂旺不予负责。存在或源于此类数据供应商资源之任何内容、商品或服务的真实性，兰铂旺亦不予保证或负责。因使用或依赖任何此类数据供应商资源发布的或经由此类数据供应商资源获得的任何内容、商品或服务所产生的任何损害或损失，兰铂旺不承担任何责任
    </p>
    <p class="register-text">
      —— 按照法律法规规定或者相关方之间的约定，数据供应商提供相应资源、服务可能需要取得必要的批准或授权，兰铂旺作为服务平台运营主体，没有途径也没有适当的权限监督、核查数据供应商是否已经取得必要的批准或授权。虽然兰铂旺会尽量通过平台维护、软件升级等方式提高用户体验及服务质量，用户认可并同意兰铂旺仅提供平台服务，不对数据供应商提供的服务的质量、服务内容是否取得必要批准或授权进行保证，也不为此承担任何责任。
    </p>
    <p class="register-text agreement-title">
      10、服务条款的变更
    </p>
    <p class="register-text">
      兰铂旺有权视需要对服务条款内容作出补充、删除或变更（以下统称“服务条款变更”），并在其官方网站相应位置进行公布，而无需另行通知用户。若用户不接受服务条款变更，可随时停止使用兰铂旺提供的服务。 若用户在服务条款变更公示后仍继续使用兰铂旺提供的服务，则视为用户已经阅读并接受服务条款变更，并愿意受变更后服务条款的约束。
    </p>
    <p class="register-text agreement-title">
      11、法律适用及争议解决
    </p>
    <p class="register-text">
      本《协议》的效力、履行和解释均适用中华人民共和国法律。若用户因本《协议》相关内容与兰铂旺产生争议，双方可协商解决。协商无法解决的，任何一方均有权向兰铂旺运营方所在地有管辖权的法院提起诉讼。
    </p>
    <p class="register-text agreement-title">
      12、其他规定
    </p>
    <p class="register-text">
      12.1 如本《协议》中的任何条款无论因何种原因完全或部分无效或不具有执行力，本《协议》的其余条款仍应有效并且有约束力；
    </p>
    <p class="register-text">
      12.2 本《协议》中的标题仅为方便阅读而设，不应影响对本《协议》条款的理解。
    </p>
    <p class="register-text">
      12.3 兰铂旺有权按需修改协议相关内容，以在公司网络平台公示的方式通知用户，用户继续使用公司服务，视为同意。
    </p>
    <p class="register-text agreement-title">
      13、意见及建议
    </p>
    <p class="register-text">
      用户对兰铂旺服务有任何意见或建议，可向兰铂旺官方网站（https://99num.com）反馈，兰铂旺愿意倾听并尊重每一位用户的意见，并将尽力为用户提供最优质的服务。
    </p>
    <p class="register-text agreement-title">
      14、法律适用与管辖
    </p>
    <p class="register-text">
      本服务条款之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本服务条款产生之争议，均应依照中华人民共和国法律予以处理，并提交浙江省杭州市余杭区人民法院审判。
    </p>
  </div>
</template>