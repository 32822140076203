<template>
  <div
      style="position: relative"
      :class="{ 'is-index': isIndex }"
  >
    <div class="layout-header header">
      <div class="header-content">
        <div class="header-left">
          <!-- logo Start -->

          <h1>
            <img
                src="../assets/img/logo01.png"
                class="header-logo"
                style="margin-right:0"
            />
            <img
                src="../assets/img/logo02.png"
                class="header-logo"
                style="margin-left:15px"
            />
          </h1>

          <!-- logo End -->

          <!-- 功能 Start -->

          <ul class="header-ul">
            <a
                class="header-li"
                :class="{
                'nuxt-link-exact-active nuxt-link-active active-li': idx == 1,
              }"
                @click="changeTab(1)"
            >
              平台首页</a>

<!--            <a-->
<!--                class="header-li"-->
<!--                :class="{-->
<!--                'nuxt-link-exact-active nuxt-link-active active-li': idx == 2,-->
<!--              }"-->
<!--                @click="changeTab(2)"-->
<!--                v-if="productList.length == 0"-->
<!--            >-->
<!--              产品服务</a>-->

<!--            <a-->
<!--                class="header-li"-->
<!--                :class="{-->
<!--                'nuxt-link-exact-active nuxt-link-active active-li': idx == 2,-->
<!--              }"-->
<!--                @click="changeTab(2)"-->
<!--                v-if="productList.length != 0"-->
<!--            >-->
<!--              <el-dropdown>-->
<!--                <span-->
<!--                    class="el-dropdown-link"-->
<!--                    :style="{ color: isIndex ? '#043649' : '#043649' ,padding:'20px 0px'}"-->
<!--                >-->
<!--                  产品服务-->
<!--                </span>-->
<!--                <template #dropdown>-->
<!--                  <el-dropdown-menu>-->
<!--                    <el-dropdown-item-->
<!--                        :divided="idx == 0 ? false : true"-->
<!--                        v-for="(item, idx) in productList"-->
<!--                        :key="idx"-->
<!--                        @click="jumpUrl(item.id)"-->
<!--                    >{{ item.name }}</el-dropdown-item>-->
<!--                  </el-dropdown-menu>-->
<!--                </template>-->
<!--              </el-dropdown>-->
<!--            </a>-->

            <a
                class="header-li"
                :class="{
                'nuxt-link-exact-active nuxt-link-active active-li': idx == 3,
              }"
                @click="changeTab(3)"
            >
              关于我们</a>
          </ul>

          <!-- 功能 End -->
        </div>

        <div class="header-right">
<!--          <div style="width: 200px; margin-right: 30px">-->
<!--            <el-input-->
<!--                placeholder="身份证"-->
<!--                v-model="search"-->
<!--                @change="hotTextClick(search)"-->
<!--                @focus="focusText"-->
<!--                @keypress="onKeyPress"-->
<!--                :class="{ elInput: isIndex }"-->
<!--            >-->
<!--              <template #suffix>-->
<!--                <i-->
<!--                    class="el-input__icon el-icon-search"-->
<!--                    style="color:#075F82"-->
<!--                    @click="hotTextClick(search)"-->
<!--                ></i>-->
<!--              </template>-->
<!--            </el-input>-->
<!--          </div>-->

          <div
              v-if="loginState"
              class="userInfo"
          >
            <el-dropdown>
              <div class="userBox">
                <span :style="{ color: isIndex ? '#FFFFFF' : '#333'}">{{MchInfo.account}}</span>
                <img src="../assets/img/content-head.png">
              </div>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <div class="popover-content-list">
                      <p class="popover-content-text">手机：<span>17803876276</span></p>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div class="popover-content-list">
                      <p class="popover-content-text">邮箱：<span>{{MchInfo.email?MchInfo.email:'未绑定'}}</span><span
                          class="content-bind-btn"
                          v-if="!MchInfo.email"
                      >去绑定</span></p>

                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div class="popover-content-list">
                      <p class="popover-content-text">类型：<span>{{MchInfo.anctType}}</span><span
                          class="content-bind-btn"
                          v-if="!MchInfo.anctType"
                      >去认证</span></p>
                    </div>
                  </el-dropdown-item>

                  <div class="popover-btn-box">
                    <p class="popover-btn">账户资料</p>
                    <p class="popover-btn">安全设置</p>
                    <p
                        class="popover-btn"
                        @click="logoutClick()"
                    >退出</p>
                  </div>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

            <p
                style="height:30px;border-radius: 6px;font-size:14px;margin-left: 30px"
                class="merchant-btn"
                @click="meClick()"
            >我的数据</p>
          </div>

          <p
              v-if="!loginState"
              style="height:30px;border-radius: 6px;font-size:14px"
              class="login-text"
              @click="loginClick()"
          >登录</p>
        </div>
      </div>
    </div>
    <div
        class="index-banner"
        v-if="isBanner"
    >
      <el-carousel :height="bannerHeight + 'px'">
        <el-carousel-item
            v-for="(item, index) in carousels"
            :key="index"
            @click="bannerClick(item.linkUrl)"
        >
          <img
              ref="bannerHeight"
              :src="item.imgUrl"
              style="width:100%;"
              @load="imgLoad"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { reactive, toRefs } from "@vue/reactivity";
import { post, get, mothodGet } from "@/utils/httpUtils";

export default {
  props: {
    tabindex: {
      type: Number || String,
      default: 1,
    },
    carousels: {
      type: Array,
      default: [
        {
          imgUrl: require("../assets/img/aboutUs/2.jpg")
          // linkUrl: "https://www.aliyun.com/page-source/market/h5/lanbowang",
        },
        {
          imgUrl: require("../assets/img/aboutUs/1.jpg")
          // linkUrl: "https://www.aliyun.com/page-source/market/h5/lanbowang",
        },
        {
          imgUrl: require("../assets/img/aboutUs/3.jpg")
          // linkUrl: "https://www.aliyun.com/page-source/market/h5/lanbowang",
        }
      ],
    },
    isBanner: {
      // 是否显示轮播图
      type: Boolean,
      default: false,
    },
    isIndex: {
      type: Boolean, // 是否是首页 用于判断样式
      default: false,
    },
  },
  data() {
    return {
      productList: [],
      search: "",
      loginState: false,
      bannerHeight: "",

      MchInfo: {}, // 个人信息
    };
  },
  setup(props) {
    const myData = toRefs(
        reactive({
          idx: props.tabindex,
        })
    );

    const myMethods = {
      changeTab(idx) {
        let that = this;

        this.idx = idx;

        let url;
        switch (idx) {
          case 1:
            url = that.$router.resolve({ path: "/" });
            break;
          case 2:
            url = that.$router.resolve({ path: "/product" });
            break;
          case 3:
            url = that.$router.resolve({ path: "/AboutUs" });
            break;
        }
        window.location.href = url.href;
      },
    };

    return {
      ...myData,
      ...myMethods,
    };
  },
  created() {
    // let aaa =
    //   '{"value":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjI1MTA4MjUsInVzZXJuYW1lIjoiYWRtaW4ifQ.NUJj0wG-aru2qCnJlCYxGKpneFG-zHcDW29jsILDU7Y","expire":1623113855141}';
    // localStorage.setItem("pro__Access-Token", aaa);

    // console.log(JSON.parse(localStorage.getItem('pro__Access-Token')).value)
    let token = JSON.parse(localStorage.getItem("pro__Access-Token"));
    // token = 1;
    // console.log(token);

    if (token) {
      this.loginState = true;

      // 个人信息
      mothodGet("/jeecg-boot/manage/mch/info").then((res) => {
        // console.log(res);

        if (res.data.result) {
          this.MchInfo = res.data.result;
        }
      });
    }

    // 产品 大类
    mothodGet("/jeecg-boot/website/index/catalog/list").then((res) => {
      this.productList = res.data.result;
    });
  },
  mounted() {
    this.imgLoad();
    window.addEventListener(
        "resize",
        () => {
          this.bannerHeight = this.$refs.bannerHeight.height;
          this.imgLoad();
        },
        false
    );
  },
  methods: {
    // 轮播图
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight.height;
        console.log(this.$refs.bannerHeight.height);
      });
    },
    // 点击跳转
    jumpUrl(id) {
      let url = this.$router.resolve({
        path: "/product",
        query: { catalogId: id },
      });

      // console.log(url);

      window.open(url.href, "_blank");
    },

    // 热词点击搜索
    hotTextClick(title) {
      let url = this.$router.resolve({
        path: "/product",
        query: { search: title },
      });

      window.location.href = url.href;
    },

    // // 聚焦赋值
    // focusText() {
    //   if (!this.search) {
    //     this.search = "身份证";
    //   }
    // },

    // // 回车事件
    // onKeyPress(e) {
    //   var keyCode = null;
    //
    //   if (e.which) keyCode = e.which;
    //   else if (e.keyCode) keyCode = e.keyCode;
    //
    //   if (keyCode == 13) {
    //     let url = this.$router.resolve({
    //       path: "/product",
    //       query: { search: this.search },
    //     });
    //
    //     window.location.href = url.href;
    //     return false;
    //   }
    //   return true;
    // },

    // 登录按钮点击
    loginClick() {
      window.location.href = "http://manage.shlbw.com/user/login?loginSource=1";
    },

    // 登出按钮点击
    logoutClick() {
      let that = this;
      // 登出
      mothodGet("http://manage.shlbw.com/sys/logout").then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          localStorage.removeItem("pro__Access-Token");
          that.loginState = false;
          ElMessage.success({ message: "退出成功" });
        } else {
          ElMessage.error({ message: "退出失败" });
        }
      });
    },

    // 我的数据
    meClick() {
      window.open("http://manage.shlbw.com/dashboard/analysis");
    },

    // banner点击跳转
    bannerClick(link) {
      if (link) {
        window.open(link);
      }
    },
  },
};
</script>


<style lang="less">
.header-left {
  h1 {
    display: flex;
    align-items: center;
  }
}

.header-right {
  .el-input__inner {
    background: none !important;
    height: 30px !important;
    color: #075f82;
    border: 1px solid #075f82 !important;

    &:hover {
      border-color: #24b1e6;
    }
  }

  .el-input__icon {
    cursor: pointer;
  }

  .el-input__inner::placeholder {
    color: #075f82 !important;
  }
  /* 谷歌 */
  .el-input__inner::-webkit-input-placeholder {
    color: #075f82 !important;
  }
  /* 火狐 */
  .el-input__inner:-moz-placeholder {
    color: #075f82 !important;
  }
  /*ie*/
  .el-input__inner:-ms-input-placeholder {
    color: #075f82 !important;
  }
}

.userInfo {
  display: flex;
  justify-content: space-between;

  .userBox {
    display: flex;
    align-items: center;
    color: #fff;

    img {
      display: block;
      width: 30px;
      border-radius: 50%;
      margin-left: 15px;
    }
  }
}

.popover-content-list {
  .content-bind-btn {
    font-size: 14px;
    color: #ff6161;
    cursor: pointer;
    margin-left: 10px;
  }
}

.popover-btn-box {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #f2f2f2;
  padding-top: 12px;
  font-size: 14px;
  color: #919191;
  cursor: pointer;

  .popover-btn {
    &:hover {
      color: #666;
    }
  }
}
</style>
