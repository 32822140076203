<template>
  <div class="index">
    <!-- header Start -->

    <com-header
        :isBanner="true"
        :isIndex="true"
    />

    <!-- header End -->

    <!-- 轮播图 Start -->

    <!-- 轮播图 End -->

    <!-- 内容 Start -->

    <!--    <div class="home">-->
    <!--      <div class="home-content">-->
    <!--        <div class="home-content-container">-->
    <!--          <div class="home-content-box">-->
    <!--            <fun-list-->
    <!--              v-if="funListData1"-->
    <!--              :list="funListData1"-->
    <!--            />-->

    <!--            <fun-list-->
    <!--              v-if="funListData2"-->
    <!--              :list="funListData2"-->
    <!--              :isImgShow="false"-->
    <!--            />-->

    <!--            <fun-list-->
    <!--              v-if="funListData3"-->
    <!--              :list="funListData3"-->
    <!--              :isList="false"-->
    <!--              :isArrList="true"-->
    <!--            />-->

    <!--            <div-->
    <!--              v-for="(item,idx) in funListDataArr"-->
    <!--              :key="idx"-->
    <!--            >-->
    <!--              <fun-list-->
    <!--                v-if="item"-->
    <!--                :list="item"-->
    <!--                :isTitle="false"-->
    <!--                :isImgShow="(idx%2 ==0) ? false:true"-->
    <!--              />-->
    <!--            </div>-->

    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="contentBox">
      <!-- 公司简介 -->
      <div
          class="index_main2_container"
          id="company_profile"
      >
        <p class="titleP">//<span>公司简介</span>//</p>

        <div class="primary_box">
          <div class="left">
            <div class="secondary_title">
              上海兰铂旺网络科技有限公司
            </div>
            <div class="index_main2_content">
              上海兰铂旺网络科技有限公司（简称"兰铂旺科技"）成立于2016年，核心团队成员来自于支付公司、银行、运营商以及国内领先的大数据人工智能企业，具备深厚的专业知识背景和丰富的行业从业经验。            </div>
            <div class="index_main2_content">
              公司自成立以来，坚持“合规、稳健、创新、发展”的原则，依托自身核心技术能力致力于为金融企业、互联网企业、中小型创新以及综合型商业客户提供领先的数字科技智能服务。            </div>
            <div
                class="index_main2_content"
                style="margin-bottom:0;"
            >
              目前公司已拥有ISO9001、ISO20000、ISO27001，增值电信业务经营许可证、软件产品证书、知识产权管理体系认证等多项资质证书，并被评为上海市科技型中小企业。            </div>
          </div>

          <img
              src="../assets/img/introduction_img.png"
              class="right"
          >
        </div>
      </div>

      <!-- 服务 -->
      <div class="security">
        <p class="titleP">//<span>数据安全和可靠服务</span>//</p>
        <div class="security-des">保证每一个客户放心使用</div>

        <div class="security-logo">
          <ul class="security-logo-item">
            <li class="security-logo-item-pic security-logo-item-pic160"> <img src="https://object-1301460840.cos.ap-shanghai.myqcloud.com/lbw-manage/sc/sc1.svg"> </li>
            <li class="security-logo-item-name">科技型中小企业</li>
          </ul>
          <!--          <ul class="security-logo-item">-->
          <!--            <li class="security-logo-item-pic security-logo-item-pic160"> <img src="https://juheimg.oss-cn-hangzhou.aliyuncs.com/www/v9/home/security-logo-item02.svg"> </li>-->
          <!--            <li class="security-logo-item-name">CMMI3</li>-->
          <!--          </ul>-->
          <ul class="security-logo-item">
            <li class="security-logo-item-pic security-logo-item-pic73"> <img src="https://object-1301460840.cos.ap-shanghai.myqcloud.com/lbw-manage/sc/sc2.png"> </li>
            <li class="security-logo-item-name">ISO9001</li>
          </ul>
          <ul class="security-logo-item">
            <li class="security-logo-item-pic security-logo-item-pic73"> <img src="https://object-1301460840.cos.ap-shanghai.myqcloud.com/lbw-manage/sc/sc3.png"> </li>
            <li class="security-logo-item-name">ISO20000</li>
          </ul>
          <ul class="security-logo-item">
            <li class="security-logo-item-pic security-logo-item-pic73"> <img src="https://object-1301460840.cos.ap-shanghai.myqcloud.com/lbw-manage/sc/sc4.png"> </li>
            <li class="security-logo-item-name">ISO27001</li>
          </ul>
          <ul class="security-logo-item">
            <!--            <li class="security-logo-item-pic security-logo-item-pic161"> <img src="https://object-1301460840.cos.ap-shanghai.myqcloud.com/lbw-manage/sc/sc5.png" style="margin-top: 9px"> </li>-->
            <li class="security-logo-item-pic security-logo-item-pic161" > <svg  style="color: red" width="5rem" height="5rem" xmlns="http://www.w3.org/2000/svg" name="zi_icp" viewBox="0 0 2000 2000"><path d="M897 1979q15 0 30-2 7-1 42.5-9.5t91-29.5q55.5-21 123.5-59t139-97.5q71-59.5 137.5-144t119.5-199q53-114.5 84.5-262.5t31.5-336V583q0-69-57-121-55-44-127-41-5 1-45 1-34 0-92.5-4T1254 401q-57-15-116.5-53.5T1036 276q-28-22-45-33-43-25-93.5-25T803 244q-2 1-14 11-90 78-151 109.5T548 401q-63 13-122 17t-93 4q-38 0-45-1-73-4-127 41-57 52-57 121v257q0 188 31 336.5t84 262.5q53 114 118.5 199T474 1782q71 59 138.5 97t123 59q55.5 21 90.5 30t42 9q15 2 29 2zM277 540h5q8 1 51 1 38 0 104-4t137-20q134-30 295-174 14-7 30.5-6t29.5 8q5 3 14 10t20 15l1 1q48 38 118 82.5t145 63.5q72 16 137.5 20t102.5 4q43 0 51-1h5q21 0 38 13 15 14 15 30v257q0 169-27 302t-73 236q-46 103-104 178.5T1252 1685q-62 53-121 87t-107.5 52.5q-48.5 18.5-79 26.5t-36.5 8q-11 2-21 0-7 0-45.5-10.5t-98-36q-59.5-25.5-129-73t-138.5-122Q407 1543 350 1435t-92-255q-35-147-35-340V583q0-16 15-29 17-14 39-14zm-77 207v104h1400V747H200zm184 194v476h99V941h-99zm528 311q-4 41-35 70-30 30-74 30-54 0-87-44-34-44-34-131 0-85 31-130 31-44 87-44 87 0 112 107l92-8q-14-81-69-125-54-43-136-43-104 0-164 68t-60 177q0 67 26 124 25 56 72 88 47 33 122 33 90 0 145-46t64-118l-92-8zm270 165v-189h137q78 0 128-40 51-41 51-106 0-57-47-99-46-42-143-42h-225v476h99zm0-262v-143h108q59 0 81 21 23 21 23 50 0 33-25 52-24 20-70 20h-117z"/></svg>
            </li>
            <li class="security-logo-item-name">ICP</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 内容 End -->

    <!-- footer Start -->

    <com-footer />

    <!-- footer End -->
  </div>
</template>



<script>
import ComFooter from "@/components/footer.vue"; // 引入footer组件
import ComHeader from "@/components/header.vue"; // 引入header组件
import funList from "@/components/funList.vue"; // 引入方法组件
import { post, get, mothodGet } from "@/utils/httpUtils";
export default {
  name: "Index",
  data() {
    return {
      carousels: [], // 轮播图 list

      funListData1: "",

      funListData2: "",

      funListData3: "",

      funListDataArr: [],
    };
  },
  created() {
    // this.GetCarousels(); // 获取 轮播图

    // 首页 列表
    // mothodGet("/jeecg-boot/website/index/index/list").then((res) => {
    //   this.funListData1 = res.data.result.hotList;
    //   this.funListData2 = res.data.result.newList;
    //   this.funListData3 = res.data.result.lbwBizTypeFunAll;
    //
    //   let lbwBizTypeFunAll = res.data.result.lbwBizTypeFunAll;
    //   for (let i = 1; i < lbwBizTypeFunAll.lbwBizTypeFunList.length; i++) {
    //     this.funListDataArr.push(lbwBizTypeFunAll.lbwBizTypeFunList[i]);
    //   }
    // });
  },
  components: {
    "com-header": ComHeader,
    "com-footer": ComFooter,
    // "fun-list": funList,
  },
  methods: {
    // 获取 轮播图
    GetCarousels() {
      let that = this;
      mothodGet("/jeecg-boot/website/index/bigPhoto").then((res) => {
        if (res.data.code == 200) {
          let data = res.data.result;

          that.carousels = data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-carousel__item img {
  width: 100%;
  height: 100%;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

// title
.titleP {
  text-align: center;
  font-size: 28px;
  color: #999999;
  padding: 30px 0;

  span {
    padding: 0 30px;
    font-weight: bold;
    color: #043649;
  }
}

.contentBox {
  padding-bottom: 20px;

  // 公司简介
  .index_main2_container {
    width: 1200px;
    margin: 0 auto;
    padding: 0 60px 60px 60px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 20px;

    .titleP {
      padding-bottom: 73px;
    }

    .primary_box {
      display: flex;
      justify-content: space-between;

      .left {
        margin-right: 60px;
        font-weight: 400;
        font-size: 14px;

        .secondary_title {
          font-size: 24px;
          font-weight: bold;
          color: #043649;
          line-height: 30px;
          margin-bottom: 30px;
        }

        .index_main2_content {
          line-height: 20px;
          margin-bottom: 15px;
          text-align: justify;
          color: #043649;
        }
      }

      img {
        border: none;
        vertical-align: middle;
      }

      .right {
        width: 400px;

        img {
          width: 100%;
        }
      }
    }
  }

  // 服务
  .security {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 40px;
    background: #fff;
    margin-top: 20px;
    border-radius: 8px;

    .titleP {
      padding-bottom: 20px;
    }

    .security-des {
      font-size: 12px;
      color: #043649;
      line-height: 20px;
      text-align: center;
    }

    .security-logo {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;

      ul,
      li {
        list-style: none;
      }

      .security-logo-item {
        .security-logo-item-pic {
          width: 160px;
          height: 90px;
          text-align: center;
        }

        img {
          border: 0;
          vertical-align: middle;
        }

        .security-logo-item-pic160 {
          img {
            width: 160px;
            height: 90px;
          }
        }

        .security-logo-item-pic161 {
          img {
            width: 70px;
            height: 70px;
          }
        }

        .security-logo-item-pic73 {
          display: table-cell;
          vertical-align: middle;

          img {
            width: 73px;
            height: 70px;
          }
        }

        .security-logo-item-name {
          font-size: 12px;
          line-height: 18px;
          color: #181818;
          text-align: center;
        }
      }
    }
  }
}
</style>
