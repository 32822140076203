<template>
  <router-view />
  <EDIT />
</template>

<script>
import EDIT from "./components/Afff.vue";

export default {
  name: "App",
  components: {
    EDIT,
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}

body {
  min-width: 1300px;
  max-height: 100vh;
  overflow: auto;
  -moz-user-select: text; /*火狐*/
  -webkit-user-select: text; /*webkit浏览器*/
  -ms-user-select: text; /*IE10*/
  -khtml-user-select: text; /*早期浏览器*/
  user-select: text;
  background: #F0F5F9;
}

@import url("./assets/css/index1.css");
@import url("./assets/css/index2.css");
@import url("./assets/css/index3.css");
@import url("./assets/css/index4.css");
@import url("./assets/css/index5.css");
// @import url("./assets/css/index6.css");
@import url("./assets/css/index7.css");
@import url("./assets/css/index8.css");
@import url("./assets/css/index9.css");
@import url("./assets/css/index10.css");
@import url("./assets/css/index11.css");
@import url("./assets/css/index12.css");
@import url("./assets/css/index13.css");
@import url("./assets/css/index14.css");
@import url("./assets/css/index15.css");
</style>
