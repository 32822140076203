<template>
  <div class="createOrder">

    <!-- header -->
    <div class="content_header">
      <div class="content_header_box">
        <div class="header_logo_box">
          <img
              src="../../assets/img/logo01.png"
              class="header_logo"
              style="height:43px;margin-right:0"
          />
          <img
              src="../../assets/img/logo02.png"
              class="header_logo"
              style="height:28px;margin-left:15px"
          />
        </div>
        <div class="header_button">我的产品</div>
      </div>
    </div>

    <!-- content -->
    <div class="content-main">
      <div class="content-main-box">
        <div class="firststep-bg-box">
          <img src="../../assets/img/pay_icon1.png">
        </div>

        <div class="order-detail-box">
          <div class="icon_content_box">
            <div class="product-icon-box">
              <img src="http://img.tianyandata.cn/product-icon/202007/293e67e183-3eed-4ad7-8795-a692eb18ed6f.png">
            </div>

            <div class="product-content-box">
              <p class="product-name">身份证实名认证</p>
              <p class="product-meal">套餐：1000次<span class="product-unit">（约0.100元/次）</span></p>
            </div>
          </div>

          <p class="product-price-lable">应付金额：<span class="product-price-num">￥100</span></p>
        </div>

        <button
            type="button"
            class="el-button orderPay-button"
            @click="orderPayClick()"
        >
          <span>确认购买</span>
        </button>

        <div class="checkbox-box">
          <el-checkbox v-model="checked">我已同意</el-checkbox>
          <span
              class="agreement-link"
              @click="dialogVisible = true"
          >《兰铂旺服务协议》</span>
        </div>
      </div>
    </div>

    <p class="record-text">Copyright © 2020 上海兰铂旺网络科技有限公司 <a
        href="javascript:;"
        target="_blank"
    >沪ICP备20008428号-2</a></p>

    <el-dialog
        title="API购买服务协议"
        v-model="dialogVisible"
        width="30%"
        center
    >
      <div>
        <p class="register-text">
          欢迎您使用 上海兰铂旺网络科技有限公司 API 服务！
        </p>
        <p class="register-text agreement-title">
          兰铂旺API服务声明
        </p>
        <p class="register-text agreement-title">
          1 兰铂旺的各项API服务的所有权和运作权归兰铂旺所有。
        </p>
        <p class="register-text agreement-title">
          2 您在确认签署本服务条款之前，请务必仔细阅读本服务条款之全部内容，尤其是以粗体标注的含有免除或限制用户责任的内容。兰铂旺仅为完成注册并提交服务开通申请的用户提供API购买服务（以下称“本服务”），一旦点击“【同意】”按钮确认使用本服务，即视为用户已充分理解并同意本服务条款所有条款的含义及相应的法律后果，接受以数据电文形式订立本服务条款并接受本服务款约束。如果用户不同意本服务条款，或者无法准确理解本服条款内容，请不要进行后续操作。
        </p>
        <p class="register-text agreement-title">
          服务对象
        </p>
        <p class="register-text">
          1. 除非与兰铂旺另有书面约定,用户申请和使用兰铂旺服务（“本服务”）的，应符合国家相关法律法规和规范，以及兰铂旺相关规则、政策的规定。同时,如兰铂旺对用户使用本服务有额外要求的，用户在符合不时更新的相应要求后方可使用本服务。
        </p>
        <p class="register-text">
          2. 用户了解并同意，即使已签署本服务条款或已实际使用本服务，用户如有不符合本服务条款之情形，兰铂旺保留随时暂停或停止用户使用本服务的权利。兰铂旺有权根据业务发展需要对服务对象的范围进行调整并进行公告，而无须为此承担责任。
        </p>
        <p class="register-text agreement-title">
          服务开通、付款与扣费
        </p>
        <p class="register-text">
          1. 兰铂旺将于用户首次付款成功后的1个工作日内向用户开通相应服务。
        </p>
        <p class="register-text">
          2. 用户承诺并保证用于其付款的资金来源的合法性，否则兰铂旺有权配合司法机关，对用户的兰铂旺账户进行相应处理，包括但不限于可随时冻结用户的兰铂旺账户等，而无须事先通知用户。
        </p>
        <p class="register-text">
          3. 用户应不得随意申请退款，否则，除非用户违反本服务条款任一约定，同时明确书面提出终止使用本服务并关闭兰铂旺账户，兰铂旺均有权拒绝办理退款。
        </p>
        <p class="register-text">
          4. 用户使用本服务须按照本服务的价格体系、支付所有费用。服务商保留在用户未按照约定支付全部费用之前不向用户提供服务或技术支持，或者终止服务或技术支持的权利。
        </p>
        <p class="register-text agreement-title">
          服务形式及内容
        </p>
        <p class="register-text agreement-title">
          兰铂旺向用户提供API购买的服务。用户在本协议生效后需按照资费标准及本协议的约定支付相应服务费用，用户兰铂旺账户内该接口仍有剩余未使用请求次数，则本协议有效期自动顺延，直至剩余次数为0时，本协议自动终止。
        </p>
        <p class="register-text">
          1.用户注册成功后将获得兰铂旺用户账号及相应的密码，该用户账号和密码（统称“账户信息”）由用户负责保管；用户应当妥善保管及使用其账户信息，并应对以其用户账号进行的所有活动和事件负法律责任。用户须对在兰铂旺的注册信息的真实性、合法性、有效性、准确性承担全部责任，否则兰铂旺有权立即停止提供服务，收回账号并由用户独自承担由此产生的一切法律责任。
        </p>
        <p class="register-text">
          2.用户在此同意并确认：
        </p>
        <p class="register-text">
          (1) 兰铂旺有权根据平台规则或平台功能等限制要求用户修改或拒绝用户提交的指定信息；
        </p>
        <p class="register-text">
          (2) 兰铂旺保留在任何时候自行决定对兰铂旺平台API购买系统及其相关功能、应用、软件的变更、升级、修改、转移的权利。兰铂旺进一步保留在兰铂旺平台API购买系统中开发新的模块、功能或其它语种服务等的权利。上述所有新的模块、功能、软件服务的提供，除非兰铂旺另有说明，否则仍适用本服务协议。
        </p>
        <p class="register-text agreement-title">
          服务开通、付款与扣费
        </p>
        <p class="register-text">
          1. 兰铂旺将于用户首次付款成功后的1个工作日内向用户开通相应服务。
        </p>
        <p class="register-text">
          2. 用户承诺并保证用于其付款的资金来源的合法性，否则兰铂旺有权配合司法机关，对用户的兰铂旺账户进行相应处理，包括但不限于可随时冻结用户的兰铂旺账户等，而无须事先通知用户。
        </p>
        <p class="register-text agreement-title">
          3. 用户应不得随意申请退款，否则，除非用户违反本服务条款任一约定，同时明确书面提出终止使用本服务并关闭兰铂旺账户，兰铂旺均有权拒绝办理退款。
        </p>
        <p class="register-text agreement-title">
          用户的权利与义务
        </p>
        <p class="register-text agreement-title">
          1. 用户应保证其向兰铂旺提供的实名制认证的证件、信息等内容真实、准确、完整、及时、有效，因用户提供的证件、信息等内容不满足真实、准确、完整、及时、有效条件而引致的各种损失或责任，均由用户自行承担。
        </p>
        <p class="register-text">
          2. 用户自愿使用兰铂旺平台，并接受经兰铂旺平台提供的API购买服务。
        </p>
        <p class="register-text">
          3. 用户应根据本服务条款约定支付服务费（如有），且不得以与第三方发生纠纷为理由拒绝支付本服务条款项下的应付款项
        </p>
        <p class="register-text">
          4. 用户使用本服务需要通过电子方式签署本服务条款，并遵守并符合国家法律法规、行政规章等规范性文件、《兰铂旺用户服务协议》、兰铂旺规则、本服务条款的规定和要求。
        </p>
        <p class="register-text">
          5. 因用户操作不当或误操作造成的损失由用户自行承担，与兰铂旺无关。
        </p>
        <p class="register-text">
          6. 用户有义务按照兰铂旺平台要求提供真实、准确、完整、有效的信息资料（包括但不限于银行账户信息、信息、手机号码），对于因用户提供信息不真实或不完整所造成的损失由用户自行承担。
        </p>
        <p class="register-text">
          7. 用户应自行对其提交API购买请求以及对通过本服务进行的其他活动所引起的一切法律纠纷承担全部责任。用户同时保证，兰铂旺及其关联公司不会因用户使用本服务而构成违法、违约或者对任何第三人的侵权。
        </p>
        <p class="register-text">
          8. 用户因同意或履行本服务条款，或因使用本服务而获得的有关兰铂旺的任何信息，包括但不限于身份证、银行卡信息、违章查询数据、信息等涉及隐私的数据或信息，均为兰铂旺的保密信息，应为兰铂旺的专有财产。用户在此承诺对该等保密信息严格保密，除用于使用本服务之目的，不得自行使用或允许他人使用，亦不得向任何第三方披露。本款下的保密义务不因本服务的中止或终止而终止。如用户将上述保密信息用于非法用途，兰铂旺不承担任何责任。
        </p>
        <p class="register-text agreement-title">
          9. 若按照法律法规的规定用户须取得相关主体的批准、同意、许可或授权方可按照本服务条款约定使用兰铂旺平台的任何信息，包括但不限于身份证、银行卡信息、违章查询数据、信息等涉及隐私的数据或信息，用户承诺并保证其在签署本服务条款或使用本服务时已经取得相应的批准、同意、许可或授权。如用户因未取得相应批准、同意、许可或授权而与第三方产生争议或纠纷，兰铂旺不承担任何责任；如用户违反承诺保证而给兰铂旺造成任何损失的，由用户承担全部责任。
        </p>
        <p class="register-text">
          10. 用户如违反本服务条款的约定，或有第三方对用户提起投诉，或兰铂旺有合理的理由怀疑其违反本服务条款约定的，兰铂旺均有权要求用户提供相关证明文件，拒绝用户提交API购买请求；情节严重的，兰铂旺有权立即暂停或停止提供本服务，且不予退还用户兰铂旺账户内的余额。如因此致兰铂旺及其关联公司损失的（包括但不限于依法必须支付给第三方的赔偿款、诉讼费、律师费、公证费等），均由用户承担，用户在此授权兰铂旺自用户兰铂旺账户中径行划扣该部分款项。
        </p>
        <p class="register-text">
          11. 用户了解并同意，第三方因用户在使用本服务过程中实施的行为而要求兰铂旺及其关联公司承担责任的，兰铂旺及其关联公司有权独立判定是否和解、和解的方式与金额等。前述情形下，和解金额亦为兰铂旺及其关联公司的损失，由用户全额承担，同时用户在此授权兰铂旺及其关联公司自用户的兰铂旺账户中径行划扣该部分款项。
        </p>
        <p class="register-text">
          12. 兰铂旺有权对用户是否违反本服务条款约定或是否情节严重做出独立判定，且无须提前通知予用户。用户应自行保存与用户行为有关的全部证据，并应对无法提供重要证据而承担不利后果。
        </p>
        <p class="register-text">
          13. 用户保证不使用兰铂旺产品服务进行任何不符合中国法律法规及政策的行为，如有违反产生的一切法律责任由其自身承担而与兰铂旺无关。如用户因违反该承诺而对兰铂旺造成的任何损害，用户应当赔偿一切损失。
        </p>
        <p class="register-text">
          14. 用户知悉、确认并保证，按照本协议约定使用的兰铂旺若为非公开数据且根据相关规定需获得数据提供方或者数据相关主体的同意或授权，兰铂旺根据用户的请求向用户提供该等数据时视为用户已经取得所有合法、有效且必需的同意或授权（统称“有效授权”），若因用户未取得或未及时取得有效授权而使用该等数据产生任何纠纷或责任，概由用户自行负责，且用户应积极采取一切行之有效之行为（包括但不限于进行全额赔偿），以使兰铂旺免于因此承担任何责任或遭受任何损失。
        </p>
        <p class="register-text agreement-title">
          兰铂旺的权利与义务
        </p>
        <p class="register-text">
          1. 兰铂旺有权根据用户的实际情况或交易记录，决定是否受理用户的购买请求。兰铂旺可以根据注册信息等相关要素对用户从事交易的权限和身份进行核实。
        </p>
        <p class="register-text">
          2. 兰铂旺有权对相关功能进行升级、改造。因用户兰铂旺账户余额不足等任何原因导致订单金额支付不成功的，兰铂旺将向用户返回失败信息。因此导致用户任何损失的，该损失应由用户自行承担。
        </p>
        <p class="register-text">
          3. 尽管兰铂旺对服务提供方进行了严格的甄选，但不能控制或保证服务提供方全部信息的真实性、合法性、准确性。
        </p>
        <p class="register-text">
          4. 填写的所有信息及历史记录，或在法律允许的情况下向其他第三方披露该等信息。
        </p>
        <p class="register-text">
          5. 兰铂旺有义务在技术上确保整个系统的安全、有效、正常运行，保证用户顺利使用本服务，并向用户提供订单记录、交易资金状态、账户余额等查询服务。但兰铂旺不承担因通讯、停电故障、黑客攻击、银行、服务提供方等原因或不可抗力事件所引起交易中断、订单错误引起的责任。
        </p>
        <p class="register-text">
          6. 兰铂旺对用户在注册及使用本服务过程中提供的相关数据和信息及在交易中产生的交易记录负有保密义务，但本协议另有约定除外
        </p>
        <p class="register-text agreement-title">
          7. 兰铂旺可对用户收取合理的费用，并可能根据业务发展对费用进行变更。兰铂旺应向用户明示收费标准及其变更情况，如用户不接受相关费用标准及其变更，则不应进行交易。一旦用户使用本服务，即视为同意接受包括费用条款在内的所有约定。
        </p>
        <p class="register-text agreement-title">
          服务变更、中断、中止和终止
        </p>
        <p class="register-text">
          1. 本服务可能于下述任一情形下变更、中断、中止或终止，兰铂旺对此不承担任何责任：
        </p>
        <p class="register-text">
          (1) 用户兰铂旺账户内预存的费用消耗完毕或余额不足；
        </p>
        <p class="register-text">
          (2) 用户不再是本服务条款规定的服务对象；
        </p>
        <p class="register-text">
          (3) 用户主动向兰铂旺申请注销或终止本服务；
        </p>
        <p class="register-text">
          (4) 用户违反法律规定、本服务条款约定或相关平台规则；
        </p>
        <p class="register-text">
          (5)如因系统维护或升级的需要而需暂停网络服务，兰铂旺将尽可能事先进行通告；
        </p>
        <p class="register-text">
          (6) 用户提供虚假注册身份信息，或实施违反本协议的行为， 兰铂旺采取中止措施应当通知用户并告知中止期间，中止期间应该是合理的，中止期间届满兰铂旺应当及时恢复对用户的服务。
        </p>
        <p class="register-text">
          (7)用户有发布违法信息、严重违背社会公德、以及其他违反法令禁止性规定的行为时， 兰铂旺有权立即终止对用户提供服务；
        </p>
        <p class="register-text">
          (8)用户在接受兰铂旺服务时实施本协议中有明确约定或属于兰铂旺事先明确告知的应被终止服务的禁止性行为的，兰铂旺有权终止对用户提供服务；
        </p>
        <p class="register-text">
          (9) 其他法律规定的情形。
        </p>
        <p class="register-text">
          2. 本服务终止时，兰铂旺有权不经通知用户同时终止本服务条款。无论本服务条款因何原因中止或终止，在本服务条款中止或终止前的行为所导致的任何赔偿和责任，用户应完全且独立地承担责任。
        </p>
        <p class="register-text agreement-title">
          法律及争议解决
        </p>
        <p class="register-text">
          1、本协议的订立、执行和解释及争议的解决均应适用【中华人民共和国法律】。
        </p>
        <p class="register-text">
          2、因双方就本协议的签订、履行或解释发生争议，双方应努力友好协商解决。如协商不成，任何一方均应向互联网法院起诉。
        </p>
        <p class="register-text agreement-title">
          附则
        </p>
        <p class="register-text">
          1. 本服务条款采用电子文本形式制成，并永久保存在兰铂旺为此设立的专用服务器上备查，双方均认可该形式的协议效力。
        </p>
        <p class="register-text">
          2. 本服务条款自用户<span class="agreement-title">申请购买API</span>服务之时生效。
        </p>
        <p class="register-text">
          3. 如本协议约定的条款与用户和公司签订的纸质合同中各条款有不同之处，以纸质合同为准；纸质合同没有约定的事项，以用户在公司平台注册使用过程中同意的各项服务条款为准。
        </p>
        <p class="register-text">
          4. 兰铂旺有权按需修改协议相关内容，以在公司网络平台公示的方式通知用户，用户继续使用公司服务，视为同意。
        </p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
              type="primary"
              @click="dialogVisible = false"
          >我已阅读</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  name: "createOrder",
  data() {
    return {
      checked: true,
      dialogVisible: false,
    };
  },
  created() {},
  methods: {
    // 购买按钮
    orderPayClick() {
      if (this.checked) {
        let url = this.$router.resolve({
          path: "/payOrder",
          query: {},
        });

        // console.log(url);

        window.location.href = url.href;
      } else {
        ElMessage.error("请先同意兰铂旺服务协议!");
      }
    },
  },
};
</script>

<style lang="less">
.el-dialog {
  margin-top: 10vh !important;
  max-height: 750px;
  min-height: 250px;

  .el-dialog__header {
    padding: 26px 30px 5px;

    .el-dialog__title {
      font-weight: 700;
    }
  }

  .el-dialog__body {
    max-height: 500px;
    overflow-y: auto;
    padding: 20px 35px;

    .agreement-title {
      font-weight: 700;
      color: #303133;
      margin: 10px 0;
    }

    p {
      line-height: 30px;
    }
  }
}

.createOrder {
  .content_header {
    height: 71px;
    background: #fff;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;

    .content_header_box {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .header_button {
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #036eb7;
        color: #036eb7;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .content-main {
    display: flex;
    justify-content: center;

    .content-main-box {
      width: 1200px;
      height: 656px;
      background: #fff;
      margin-top: 20px;
      padding: 32px;
      box-sizing: border-box;

      .firststep-bg-box {
        img {
          display: block;
          margin: 8px auto 0;
        }
      }

      .order-detail-box {
        height: 100px;
        background: #f3f3f3;
        margin-top: 40px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #333;

        .icon_content_box {
          display: flex;

          .product-icon-box {
            width: 90px;
            height: 90px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .product-content-box {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .product-name {
              font-weight: 700;
              font-size: 16px;
            }

            .product-meal {
              .product-unit {
                color: #919191;
              }
            }
          }
        }

        .product-price-lable {
          .product-price-num {
            font-size: 16px;
            color: #f53d3d;
          }
        }
      }

      .orderPay-button {
        width: 120px;
        height: 46px;
        background: #036eb7;
        font-size: 14px;
        color: #fff;
        margin-top: 12px;
      }

      .checkbox-box {
        margin-top: 12px;
        font-size: 14px;

        .agreement-link {
          color: #036eb7;
          cursor: pointer;
        }
      }
    }
  }

  .record-text {
    color: #919191;
    font-size: 14px;
    text-align: center;
    padding: 20px 0;

    a {
      color: #919191;
      cursor: pointer;
    }
  }
}
</style>
