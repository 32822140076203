<template>
  <!-- <div class="footer layout-footer">
    <div class="footer-content">
      <div class="content-list">
        <ul
          class="footer-ul"
          v-for="(item, idx) in list"
          :key="idx"
        >
          <li
            v-for="(it, index) in item"
            :key="index"
            class="footer-li"
            :class="{ 'title-li': index == 0, none: !it.isLink }"
          >
            <a
              :href="it.url"
              target="_blank"
            >{{ it.title }}</a>
          </li>
          <li
            v-for="(it, index) in item"
            :key="index"
            class="footer-li"
            :class="{
              'title-li': index == 0,
              none: it.isLink,
              'no-link': !it.isLink,
              'number-li': it.isNumber,
            }"
          >
            {{ it.title }}
          </li>
        </ul>
      </div>
      <p class="record-text">
        Copyright © 2020 上海兰铂旺网络科技有限公司
        <a
          href="javascript:;"
          target="_blank"
          class="number-text"
        >沪ICP备20008428号-2</a>
      </p>
    </div>
  </div> -->

  <div class="footer_wrap">
    <div class="footer_box">
      <div class="clearfix">
        <div class="left">
          <ul>
            <li>关于兰铂旺</li>
            <li>
              <a
                href="javascript:;"
                @click="jumpUrl(1)"
              >公司介绍</a>
            </li>
            <li>
              <a
                href="javascript:;"
                @click="jumpUrl(2)"
              >公司资质</a>
            </li>
            <li>
              <a
                href="javascript:;"
                @click="jumpUrl(3)"
              >联系我们</a>
            </li>
          </ul>

          <ul>
            <li>联系我们</li>
            <li>
              服务热线：021-58356602
            </li>
            <li>
              商务邮箱：business@99num.com
            </li>
            <li>
              公司地址：上海市浦东新区祥科路58号B栋
            </li>
          </ul>
        </div>

        <div class="qr_code">
          <img src="../assets/img/code.png" style="height: 120px">
          <div>扫码关注</div>
        </div>
      </div>

      <div class="copyright">
        Copyright © 2020 上海兰铂旺网络科技有限公司 <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >沪ICP备20008428号-5</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "comFooter",
  props: {
    list: {
      type: Array,
      default: [
        [
          {
            url: "#",
            title: "产品服务",
            isLink: true,
          },
          {
            url: "#",
            title: "产品服务",
            isLink: true,
          },
          {
            url: "#",
            title: "产品服务",
            isLink: true,
          },
        ],
      ],
    },
  },
  methods: {
    jumpUrl(type) {
      let that = this;
      let url;
      switch (type) {
        case 1:
          url = that.$router.resolve({ path: "/" }).href + '#company_profile';
          break;
        case 2:
          url = that.$router.resolve({ path: "/AboutUs" }).href + '#iframe_';
          break;
        case 3:
          url = that.$router.resolve({ path: "/AboutUs" }).href + '#sh_about_us_contact_us';
          break;
      }
      window.location.href = url;
    },
  },
};
</script>

<style lang="less" scoped>
.footer_wrap {
  background: #00031c;
  height: 250px;
  font-size: 14px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding-top: 33px;
  box-sizing: border-box;
  background: url(../assets/img/footer_bg.png) no-repeat center center;

  .footer_box {
    width: 806px;
    margin: 0 auto;

    .clearfix {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;

        ul li:first-child {
          color: #fff;
          font-size: 16px;
          margin-bottom: 20px;
        }

        ul {
          margin-right: 100px;

          li {
            margin-bottom: 15px;
            color: #a5a8b1;

            a {
              color: #a5a8b1;

              &:hover {
                color: #fff;
              }
            }
          }
        }
      }

      .qr_code {
        text-align: center;

        div {
          margin-top: 10px;
          color: #fff;
        }
      }
    }

    .copyright {
      font-size: 12px;
      color: #a5a8b1;
      margin-top: 17px;

      a {
        color: #a5a8b1;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
