<template>
  <div class="register">
    <!-- 头部 -->
    <div class="login-header">
      <a href="https://www.tianyandata.cn">
        <img
          src="https://z3.ax1x.com/2021/05/17/gRPbXd.png"
          class="login-header-logo"
        ></a>
    </div>

    <div class="login-content-container">
      <div class="register-content">

        <!-- 验证码找回 -->
        <div
          class="first-step"
          v-if="!alterState"
        >
          <p class="register-title">验证码找回</p>

          <div class="input-container">
            <div class="register-input el-input">
              <input
                type="text"
                autocomplete="off"
                placeholder="手机号"
                class="el-input__inner"
              >
            </div>
          </div>

          <div class="input-container">
            <div class="register-code-input el-input">
              <input
                type="text"
                autocomplete="off"
                placeholder="请输入验证码"
                class="el-input__inner"
              >
            </div> <button
              type="button"
              class="el-button active-btn el-button--default"
            ><span>获取验证码</span>
            </button>

          </div>

          <button
            type="button"
            class="el-button register-btn el-button--primary"
            @click="nextStepClick()"
          ><span>下一步</span>
          </button>

          <router-link to='Login'>
            <p class="login-tip"><span class="login-link">返回登录</span></p>
          </router-link>
        </div>

        <!-- 设置密码 -->
        <div
          class="last-step"
          v-if="alterState"
        >
          <p class="register-title">设置密码</p>
          <div class="input-container">
            <div class="register-input el-input">
              <input
                type="password"
                autocomplete="off"
                placeholder="新密码"
                class="el-input__inner"
              >
            </div>
          </div>

          <div class="input-container">
            <div class="register-input el-input">
              <input
                type="password"
                autocomplete="off"
                placeholder="确认密码"
                class="el-input__inner"
              >
            </div>
          </div>

          <button
            type="button"
            class="el-button register-btn el-button--primary"
          >
            <span>确认修改</span>
          </button>

          <router-link to='Login'>
            <p class="login-tip"><span class="login-link">返回登录</span></p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Forget",
  data() {
    return {
      alterState: false, // 填写 密码框 状态
    };
  },
  components: {},
  methods: {
    // 下一个 按钮点击
    nextStepClick() {
      this.alterState = true;
    },
  },
};
</script>

<style lang="less" scoped>
.register {
  .register-content {
    width: 1200px;
    background-color: #fff;
    height: 280px;
    padding: 40px 0;
    border-radius: 5px;
  }

  .first-step,
  .last-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .input-container {
    .register-input {
      width: 380px;
    }

    .register-code-input {
      width: 240px;
    }
  }

  .register-btn {
    width: 380px;
    margin-top: 50px;
  }

  .login-tip {
    width: 380px;
    color: #333;
    font-size: 13px;
    text-align: right;
    margin-top: 20px;
  }

  .login-link {
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    color: #036eb7;
    cursor: pointer;
  }
}
</style>