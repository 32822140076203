import axios from 'axios'
import { baseUrl } from './api'
import qs from 'qs'

// console.log(baseUrl)

axios.interceptors.request.use(config => {
    // loading
    return config
}, error => {
    return Promise.reject(error)
});

axios.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.resolve(error.response)
});

function checkStatus(response) {

    // loading
    // 如果http状态码正常，则直接返回数据
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {

        return response
        // 如果不需要除了data之外的数据，可以直接 return response.data
    } else if (response.status === 403) {

        // axios.post(`${baseUrl}api/v2.0/common/token`, {}, a).then((res) => {
        //     // console.log(res);
        //     localStorage.setItem("deviceNo", res.data.data.deviceNo);
        //     localStorage.setItem("accessToken", res.data.data.accessToken);
        //     window.location.reload() // 重新加载页面

        // });

        return
    }
    // 异常状态下，把错误信息返回去
    return {
        status: response.status,
        msg: response.data.msg
    }
}

function mothodGet(url, data) {
    let token = JSON.parse(localStorage.getItem("pro__Access-Token"))

    let accessToken = '';
    
    if (token) {
        accessToken = token.value
    }

    return axios.get(baseUrl + url, {
        params: data,
        headers: {
            "X-Access-Token": accessToken,
        }
    }).then(res => {
        return checkStatus(res)
    })
}

function formPost(url, data) {
    // let accessToken = "Bearer " + window.localStorage.getItem("accessToken");
    let accessToken = "Bearer " + "11";

    return axios({
        method: 'post',
        baseURL: baseUrl,
        url,
        data: qs.stringify(data),
        timeout: 10000,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: accessToken,
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function post(url, data) {
    let accessToken = "Bearer " + "11";

    return axios({
        method: 'post',
        baseURL: baseUrl,
        url,
        data: JSON.stringify(data),
        timeout: 10000,
        headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function get(url, data) {
    let accessToken = "Bearer " + "11";

    return axios({
        method: 'get',
        baseURL: baseUrl,
        url,
        data: JSON.stringify(data), // get 请求时带的参数
        timeout: 10000,
        headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

export {
    mothodGet,
    formPost,
    post,
    get,
}
