<template>
  <div class="payOrder">
    <!-- header -->
    <div class="content_header">
      <div class="content_header_box">
        <div class="header_logo_box">
          <img
              src="../../assets/img/logo01.png"
              class="header_logo"
              style="height:43px;margin-right:0"
          />
          <img
              src="../../assets/img/logo02.png"
              class="header_logo"
              style="height:28px;margin-left:15px"
          />
        </div>
        <div class="header_button">我的产品</div>
      </div>
    </div>

    <!-- content -->
    <div class="content-main">
      <div class="content-fist-box">
        <div class="secondStep-bg-box">
          <img src="../../assets/img/pay_icon2.png">
        </div>

        <div class="order-status-box">
          <div class="order-content-box">
            <p class="order-status">待支付</p>
            <p class="order-time">2天23小时48分后自动取消订单</p>
          </div>
          <p class="order-number">订 单 号：542937319982709360</p>
        </div>

        <div class="order-detail-box">
          <div class="icon_content_box">
            <div class="product-icon-box">
              <img src="http://img.tianyandata.cn/product-icon/202007/293e67e183-3eed-4ad7-8795-a692eb18ed6f.png">
            </div>
            <div class="product-content-box">
              <p class="product-name">身份证实名认证</p>
              <p class="product-meal">套餐：身份证实名认证次<span class="product-unit">（约0.100元/次）</span></p>
            </div>
          </div>

          <p class="product-price-lable">应付金额：<span class="product-price-num">￥</span><span class="price-num">100</span></p>
        </div>
      </div>

      <div class="orderPay-coupon-box">
        <div class="orderPay-coupon-header">
          <label class="orderPay-type-lable">优惠券:</label>

          <div class="coupon-header-content">
            <div class="coupon-header-left">
              <el-checkbox
                  v-model="checked"
                  @change="couponChange"
              >不使用优惠券</el-checkbox>
            </div>
            <div class="coupon-header-right">
              <p>优惠券：<span class="orderPay-num-text">￥0</span></p>
            </div>
          </div>
        </div>

        <div class="orderPay-coupon-content">
          <div
              class="orderPay-coupon-list"
              :class="[{'orderPay-coupon-overdue':!item.couponType},{'orderPay-activeCoupon-list':couponState === index}]"
              v-for="(item,index) in couponList"
              :key="index"
              @click="couponClick(item.couponType,index)"
          >
            <p class="finance-coupon-content">
              <span class="finance-coupon-unit">￥</span>
              <span class="finance-coupon-num">{{item.couponNum}}</span>
              <span class="finance-coupon-condition">满{{item.couponCondition}}元可用</span>
            </p>
            <p class="finance-coupon-time">有效期：{{item.couponTime}}</p>

            <img
                class="checked-icon"
                v-if="couponState === index"
                src="../../assets/img/coupon/pitchOn2.png"
            >
          </div>
        </div>
      </div>

      <div class="orderPay-type-box">
        <div class="orderPay-content-left">
          <label class="orderPay-type-lable">支付方式:</label>

          <div
              class="orderPay-type-list"
              :class="{'orderPay-active-type':payType=='wx'}"
              @click="payTypeClick('wx')"
          >
            <div
                class="select-default-box"
                :class="{'select-active-box':payType=='wx'}"
            >
              <div class="select-inner-box"></div>
            </div>
            <p class="pay-type-name">微信支付</p>
            <img
                src="../../assets/img/wx.png"
                class="pay-type-icon"
            >
          </div>

          <div
              class="orderPay-type-list"
              :class="{'orderPay-active-type':payType=='zfb'}"
              @click="payTypeClick('zfb')"
          >
            <div
                class="select-default-box"
                :class="{'select-active-box':payType=='zfb'}"
            >
              <div class="select-inner-box"></div>
            </div>
            <p class="pay-type-name">支付宝</p>
            <img
                src="../../assets/img/zfb.png"
                class="pay-type-icon"
            >
          </div>
        </div>

        <div class="orderPay-content-right">
          <p class="orderPay-lable-text">支付：<span class="orderPay-num-text">￥100</span></p>
        </div>
      </div>

      <div class="orderPay-action-box">
        <div class="orderPay-action-content">
          <p class="orderPay-action-lable">实付金额：<span class="orderPay-action-unit">￥</span><span class="orderPay-action-num">100</span></p>
          <p class="orderPay-content-text"><span class="orderPay-action-type">优惠券：</span><span class="orderPay-action-value">￥0</span><span class="orderPay-action-type">余额抵扣：</span><span class="orderPay-action-value">￥0</span></p>
        </div>
        <button
            type="button"
            class="el-button orderPay-button el-button--default"
        >
          <span>立即支付</span>
        </button>
      </div>
    </div>

    <p class="record-text">Copyright © 2020 上海兰铂旺网络科技有限公司 <a
        href="javascript:;"
        target="_blank"
    >沪ICP备20008428号-2</a></p>

  </div>
</template>

<script>
export default {
  name: "payOrder",
  data() {
    return {
      payType: "wx", // 支付方式 wx 微信 / zfb 支付宝
      checked: false, // 优惠券 默认不使用
      couponList: [
        {
          couponType: true, // 优惠券 能用不能用
          couponNum: 5,
          couponCondition: 100,
          couponTime: "2021-08-05 00:00",
        },
        {
          couponType: true,
          couponNum: 5,
          couponCondition: 100,
          couponTime: "2021-08-05 00:00",
        },
        {
          couponType: false,
          couponNum: 20,
          couponCondition: 380,
          couponTime: "2021-08-05 00:00",
        },
        {
          couponType: false,
          couponNum: 20,
          couponCondition: 380,
          couponTime: "2021-08-05 00:00",
        },
        {
          couponType: false,
          couponNum: 38,
          couponCondition: 700,
          couponTime: "2021-08-05 00:00",
        },
        {
          couponType: false,
          couponNum: 38,
          couponCondition: 700,
          couponTime: "2021-08-05 00:00",
        },
      ], // 优惠券 仿造 list
      couponState: "", // 优惠券 选中
    };
  },
  created() {},
  methods: {
    // 是否选用优惠券
    couponChange(val) {
      if (val) {
        this.couponState = "";
      }
    },

    // 选中 使用 优惠券
    couponClick(type, index) {
      if (type) {
        this.checked = false;
        this.couponState = index;
      }
    },

    // 支付类型
    payTypeClick(type) {
      this.payType = type;

      if (type == "wx") {
      } else if (type == "zfb") {
      }
    },
  },
};
</script>

<style lang="less">
.payOrder {
  .content_header {
    height: 71px;
    background: #fff;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;

    .content_header_box {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .header_button {
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #036eb7;
        color: #036eb7;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .content-main {
    width: 1200px;
    margin: 0 auto;

    .content-fist-box {
      background: #fff;
      margin-top: 20px;
      padding: 24px 32px;

      .secondStep-bg-box {
        img {
          display: block;
          margin: 8px auto 0;
        }
      }

      .order-status-box {
        height: 30px;
        background: #f3f3f3;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        border-bottom: 1px solid #e5e5e5;

        .order-content-box {
          display: flex;
          flex-direction: column;

          .order-status {
            font-size: 16px;
            font-weight: 700;
            color: #f53d3d;
          }

          .order-time {
            font-size: 14px;
            color: #919191;
            margin-top: 12px;
          }
        }

        .order-number {
          font-size: 14px;
          color: #333;
        }
      }

      .order-detail-box {
        height: 100px;
        background: #f3f3f3;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #333;

        .icon_content_box {
          display: flex;

          .product-icon-box {
            width: 90px;
            height: 90px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .product-content-box {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .product-name {
              font-weight: 700;
              font-size: 16px;
            }

            .product-meal {
              .product-unit {
                color: #919191;
              }
            }
          }
        }

        .product-price-lable {
          .product-price-num {
            font-size: 16px;
            color: #f53d3d;
          }

          .price-num {
            font-size: 26px;
            color: #f53d3d;
          }
        }
      }
    }

    .orderPay-coupon-box {
      min-height: 148px;
      background: #fff;
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .orderPay-coupon-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .orderPay-type-lable {
          padding-left: 32px;
          font-size: 14px;
          font-weight: 500;
          color: #7b7b7b;
        }

        .coupon-header-content {
          width: 1084px;
          height: 40px;
          background: #f9f9f9;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .coupon-header-left {
            padding-left: 16px;

            .el-checkbox__label {
              font-size: 12px;
            }
          }

          .coupon-header-right {
            padding-right: 32px;
            font-size: 14px;

            .orderPay-num-text {
              font-weight: 500;
              color: #f53d3d;
            }
          }
        }
      }

      .orderPay-coupon-content {
        padding: 10px 32px;
        display: flex;
        flex-wrap: wrap;

        .orderPay-coupon-list {
          width: 219.2px;
          height: 93px;
          background: url("../../assets/img/coupon/default_usable.png")
          no-repeat;
          display: flex;
          flex-direction: column;
          position: relative;
          cursor: pointer;
          margin: 0 10px 10px 0;
          color: #fff;

          &:nth-of-type(5n) {
            margin-right: 0px;
          }

          .finance-coupon-content {
            padding-left: 12px;
            margin-top: 8px;
            font-weight: 700;

            .finance-coupon-unit {
              font-size: 24px;
            }

            .finance-coupon-num {
              font-size: 30px;
              padding: 0 4px;
            }

            .finance-coupon-condition {
              font-size: 14px;
              font-weight: 400;
            }
          }

          .finance-coupon-time {
            font-size: 12px;
            padding-left: 12px;
          }

          .checked-icon {
            position: absolute;
            right: 2px;
            bottom: 2px;
          }
        }

        .orderPay-coupon-overdue {
          background: url("../../assets/img/coupon/default_disabled.png")
          no-repeat;
          color: #f5f5f5;
        }

        .orderPay-activeCoupon-list {
          background: url("../../assets/img/coupon/pitchOn1.png") no-repeat;
        }
      }
    }

    .orderPay-type-box {
      height: 88px;
      background: #fff;
      margin-top: 10px;
      padding: 0 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .orderPay-content-left {
        display: flex;
        align-items: center;

        .orderPay-type-lable {
          font-size: 16px;
          font-weight: 700;
          color: #333;
        }

        .orderPay-type-list {
          width: 190px;
          height: 60px;
          border: 1px solid #dfdfdf;
          margin-left: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .select-default-box {
            width: 20px;
            height: 20px;
            border: 1px solid #dfdfdf;
            border-radius: 50%;
          }

          .select-active-box {
            width: 20px;
            height: 20px;
            border: 1px solid #036eb7;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .select-inner-box {
              width: 12px;
              height: 12px;
              background: #036eb7;
              border-radius: 50%;
            }
          }

          .pay-type-name {
            font-size: 14px;
            color: #333;
            margin-left: 26px;
          }

          .pay-type-icon {
            width: 36px;
            height: 36px;
            margin-left: 32px;
          }
        }

        .orderPay-active-type {
          background: #ecf7ff;
        }
      }

      .orderPay-content-right {
        .orderPay-lable-text {
          font-size: 14px;

          .orderPay-num-text {
            font-weight: 500;
            color: #f53d3d;
          }
        }
      }
    }

    .orderPay-action-box {
      height: 88px;
      background: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;
      padding: 0 32px;

      .orderPay-action-content {
        .orderPay-action-lable {
          font-size: 18px;
          font-weight: 800;
          color: #333;
          text-align: right;

          .orderPay-action-unit {
            color: #f53d3d;
          }

          .orderPay-action-num {
            color: #f53d3d;
            font-size: 28px;
          }
        }

        .orderPay-content-text {
          text-align: right;
          margin-top: 5px;

          .orderPay-action-type {
            font-size: 14px;
            font-weight: 500;
            color: #7b7b7b;
            padding-left: 5px;
          }

          .orderPay-action-value {
            font-size: 14px;
            font-weight: 500;
            color: #333;
          }
        }
      }

      .orderPay-button {
        width: 120px;
        height: 46px;
        background: #036eb7;
        font-size: 16px;
        color: #fff;
        margin-left: 32px;
      }
    }
  }

  .record-text {
    color: #919191;
    font-size: 14px;
    text-align: center;
    padding: 20px 0;

    a {
      color: #919191;
      cursor: pointer;
    }
  }
}
</style>
