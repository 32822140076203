<template>
  <div class="Afff">
    <div class="edit_btn">
      <img
        @click="editShow=!editShow"
        :src="editShow ? require('../assets/edit_b.png') : require('../assets/edit.png')"
      >
    </div>

    <div
      class="edit_wrap"
      v-if="editShow"
    >
      <div class="custom_title">
        <div>在线留言</div>
        <img
          src="../assets/down.png"
          class="shrink"
          @click="editShow=!editShow"
        >
      </div>

      <div class="custom_form">
        <textarea
          autocomplete="off"
          placeholder="留言"
          maxlength="200"
          class="custom_textarea"
          v-model="userMsg"
        ></textarea>
        <input
          type="text"
          autocomplete="off"
          placeholder="姓名"
          class="custom_input"
          v-model="userName"
        >
        <input
          type="text"
          autocomplete="off"
          placeholder="电话"
          maxlength="11"
          class="custom_input"
          v-model="userPhone"
        >

        <div
          class="custom_btn"
          @click="custom_btn_click()"
        >提交</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editShow: false, // 是否显示
      userMsg: "", // 留言内容
      userName: "", // 留言姓名
      userPhone: "", // 留言电话
    };
  },
  created() {},
  methods: {
    // 留言 提交按钮
    custom_btn_click() {
      console.log(this.userMsg);
      console.log(this.userName);
      console.log(this.userPhone);
    },
  },
};
</script>

<style lang="less" scoped>
.Afff {
  position: relative;

  // 留言 btn
  .edit_btn {
    position: fixed;
    right: 20px;
    bottom: 300px;
    cursor: pointer;
    z-index: 500;

    img {
      display: block;
    }
  }

  // 留言框
  .edit_wrap {
    position: fixed;
    left: 20px;
    bottom: 200px;
    width: 300px;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 1px 11px 0px #c0c0c0;
    z-index: 500;
    .custom_title {
      height: 54px;
      color: #fff;
      background: #1377ff;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 24px;
    }

    .custom_form {
      padding: 20px 24px;

      .custom_textarea {
        width: 100%;
        min-height: 100px;
        display: block;
        resize: vertical;
        padding: 5px 15px;
        line-height: 1.5;
        box-sizing: border-box;
        font-size: 14px;
        color: #606266;
        background-color: #fff;
        background-image: none;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
          border-color: #c0c4cc;
        }
      }

      .custom_input {
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        font-size: 14px;
        color: #606266;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        margin-top: 20px;

        &:hover {
          border-color: #c0c4cc;
        }
      }

      .custom_btn {
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        border: 1px solid #dcdfe6;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        outline: 0;
        margin: 0;
        transition: 0.1s;
        font-weight: 500;
        padding: 12px 20px;
        font-size: 14px;
        border-radius: 4px;
        background-color: #1377ff;
        transition: all 0.5s;
        margin-top: 20px;

        &:hover {
          transform: scale(1.025);
        }
      }
    }
  }
}
</style>
